import React from "react";
import ReactDropdown from "react-dropdown";
import styled from "styled-components";
import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Label = styled.label`
  position: relative;
  width: 100%;
  padding-bottom: 14px;
  font-size: 16px;
`;

const LabelText = styled.div`
  color: #1976d2;
  font-family: inter;
  font-size: 14px;
`;

function DropDown({ items, selected, onChange, label }) {

  return (
    <Label>
      <LabelText>{label}</LabelText>
      <ReactDropdown
        placeholder=""
        arrowOpen={<FontAwesomeIcon icon={faAngleUp} />}
        arrowClosed={<FontAwesomeIcon icon={faAngleDown} />}
        options={items}
        onChange={onChange}
        value={selected}
      />
    </Label>
  );
}


export default DropDown;
