import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  bottom: ${({expanded}) => expanded ? '0' : '-190px'};
  width: 100%;
  padding: 16px;
  padding-bottom: 90px;
  border-radius: 24px 24px 0 0;
  border: solid 1px #aaa;
  border-bottom: none;
  background-color: #fff;
  transition: bottom 0.5s ease;
  overflow: hidden;
  z-index: 2;
`;

function DrawerPanel({ children, expanded }) {
  return (
    <Container expanded={expanded}>
      <div>{children}</div>
    </Container>
  );
}

export default DrawerPanel;
