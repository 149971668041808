import styled from "styled-components";
import { Box } from "@mui/material";

const FixedHeightContainer = styled(Box)`
  margin: 0 16px;
  // min-height: calc(100vh - 80px);
  height: 100vh;
  height: 100svh;
  width: 100%;
`;

export default FixedHeightContainer
