import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const BackButton = styled.button`
  background-color: #fff;
  background-color: transparent;
  border: none;
  color: #1976d2;
  font-size: 18px;
  position: relative;
  left: -16px;
`;

function Button({ onClick }) {
  return (
    <BackButton onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </BackButton>
  );
}

export default Button;
