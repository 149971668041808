import React from "react";
import styled from "styled-components";

const Padding = styled.span`
  padding: 8px;
  width: 100%;
  height: 100%;
  display: grid;
`;

export function PaddedIcon(props) {
  return <Padding {...props} className='padded-icon' >{props.children}</Padding>;
}
