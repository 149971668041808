import React from "react";
import styled from "styled-components";

// set up styled components to encapsulate colors and sizes for icon

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const PlayBtnTrack = styled.span`
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  animation: playbtn 4s linear infinite;
`;

const RoomContainer = styled.div`
  position: relative;
  width: 50vw;
  margin: 0 auto;
`;

const PhoneSlot = styled.span`
  position: absolute;
  left: 45%;
  bottom: 25%;
  width: 10%;
`;

const FOV = styled.span`
  position: absolute;
  left: 35%;
  bottom: 35%;
  width: 30%;
  opacity: 0.25;
  animation: followPlayBtn 4s linear infinite;
  transform-origin: bottom center;
`;

export const RecordingAnime = () => {
  const fov = () => {
    return (
      <svg viewBox="0 0 233.31 333.4">
        <g>
          <polygon
            points="116.66 333.4 233.31 0 0 0 116.66 333.4"
          />
        </g>
      </svg>
    );
  };

  const room = () => {
    return (
      <RoomContainer>
        <svg viewBox="0 0 602.21 602.21">
          <rect
            x="90.69"
            y="90.69"
            width="350.83"
            height="350.83"
            fill="none"
            stroke="#fff"
            strokeWidth={5}
            transform="translate(301.11 -124.72) rotate(45)"
          />
          <rect
            class="cls-1"
            x="90.69"
            y="90.69"
            fill="#fff"
            opacity={0.25}
            width="350.83"
            height="350.83"
            transform="translate(301.11 -124.72) rotate(45)"
          />
        </svg>
        <PhoneSlot>{phone()}</PhoneSlot>
        <FOV>{fov()}</FOV>
      </RoomContainer>
    );
  };

  const progressBar = () => {
    return (
      <>
        <ProgressBarContainer>
          <svg id="bar" viewBox="0 0 1170 255.75" width={"100%"}>
            <rect fill="#fff" opacity={0.25} width="1170" height="155.75" />
          </svg>

          <PlayBtnTrack>
            <svg id="playbtn" height={30} viewBox="0 0 124.56 143.83">
              <polygon
                width={"100%"}
                fill="#bd2119"
                points="124.56 71.91 0 0 0 143.83 124.56 71.91"
              />
            </svg>
          </PlayBtnTrack>
        </ProgressBarContainer>
      </>
    );
  };

  const phone = () => {
    return (
      <svg viewBox="0 0 67.06 97.54">
        <path d="M0,12.19C0,5.47,5.47,0,12.19,0H54.87c6.73,0,12.19,5.47,12.19,12.19V85.35c0,6.73-5.47,12.19-12.19,12.19H12.19c-6.73,0-12.19-5.47-12.19-12.19V12.19ZM39.63,85.35c0-3.37-2.72-6.1-6.1-6.1s-6.1,2.72-6.1,6.1,2.72,6.1,6.1,6.1,6.1-2.72,6.1-6.1ZM54.87,12.19H12.19v60.97H54.87V12.19Z" />
      </svg>
    );
  };
  return (
    <div style={{ fill: "red" }}>
      {progressBar()}
      {room()}
    </div>
  );
};
