import React from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { PaddedIcon } from "../../Svg/Components/PaddedIcon";

const StyledButton = styled(Button)`
  font-size: 10px;
  width: 100%;
  border: solid 1px #ddd;
  text-align: unset;
  display: flex;
  justify-content: flex-start;
  background-color: ${({ complete }) => (complete ? "#1976d2" : "#eee")};
  color: ${({ complete }) => (complete ? "#fff" : "#000")};
  padding: 16px 0;
  padding-right: 16px;
  box-shadow: 0 4px 12px 2px #ddd;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  :hover {
    background-color: ${({ complete }) => (complete ? "#1976d2" : "#eee")};
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 6em;
  padding-left: 16px;
  max-width: 100px;
  ${({ complete }) => {
    if (complete) {
      return `
        fill: white;
        stroke: white;
      `;
    }
  }}
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  // align-items: flex-start;
`;

const Header = styled.header`
  font-size: 1.8em;
  font-weight: bold;
  font-family: montserrat;
`;

const Text = styled.p`
  text-transform: initial;
  font-size: 1.2em;
  margin: 0;
  font-family: montserrat;
`;

function PanelButton({ headerText, bodyText, handleClick, complete, icon }) {
  // fontawesome icons are objects, custom icons are react function components
  const isFontAwesome = typeof icon !== "string" && typeof icon !== "function";
  // call icon as a react component if icon is not from font awesome and set innerHTML if icon is svg string
  const IconComponent = () =>
    typeof icon === "function" ? (
      icon()
    ) : (
      <span dangerouslySetInnerHTML={{ __html: icon }} />
    );

  return (
    <StyledButton complete={complete} onClick={handleClick}>
      {icon && (
        <Icon complete={complete}>
          {isFontAwesome ? (
            <FontAwesomeIcon icon={icon} />
          ) : (
            <PaddedIcon>
              <IconComponent />
            </PaddedIcon>
          )}
        </Icon>
      )}
      <Content>
        <Header>{headerText}</Header>
        {bodyText && <Text>{bodyText}</Text>}
      </Content>
    </StyledButton>
  );
}

export default PanelButton;
