import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { states } from "../../Constants/states";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { useRootStore } from "../../Stores/RootStore";
import {
  DividingContainer,
  DropDown,
  FieldSet,
  Input,
  NavContainer,
  VerticalFlexContainer,
} from "./UI";
import { NextButton } from "../UI";
import {
  validZipCode,
  defaultValidator as validInput,
} from "../../Utils/validation";
import { useValidation } from "../../Hooks/useValidation";
import { useNavigation } from "../../Providers/NavigationProvider";
import { useModules } from "../../Hooks/useModules";

function OriginLocation() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const { navigate } = useNavigation();
  const countries = Object.keys(states);
  const [stateOptions, setStateOptions] = useState([]);
  const northAmericanCountries = ['United States', 'Canada', 'Mexico'];
  const [addressType, setAddressType] = useState(northAmericanCountries.includes(surveyStore.originCountry) ? 'North American' : 'European');
  useModules();

  useEffect(() => {
    if (surveyStore.originCountry) {
      if (surveyStore.originState) {
        if (
          !states[surveyStore.originCountry]
            .map((s) => s.name)
            .includes(surveyStore.originState) && surveyStore.originState != "Other"
        ) {
          surveyStore.setOriginState("");
        }
      }
      setStateOptions(states[surveyStore.originCountry].map((s) => s.name));
      setAddressType(northAmericanCountries.includes(surveyStore.originCountry) ? 'North American' : 'European');
    }
  }, [surveyStore.originCountry, surveyStore.originState]);

  const valid = useValidation([
    { value: surveyStore.originCountry },
    { value: surveyStore.originStreetAddr },
    { value: surveyStore.originCity },
    { value: surveyStore.originState },
    {value: surveyStore.customOriginState, validator: surveyStore.originState == "Other" ? null : "noCheck"},
    { value: surveyStore.originPostalCode, validator: addressType == "European" ? null : validZipCode},
  ]);
  const submitStep = async () => {
    rootState.api
      .submitOriginLocation({
        uuid: surveyStore.surveyId,
        step: "origin_location",
        street_address: surveyStore.originStreetAddr,
        house: surveyStore.originHouseNumber,
        state: surveyStore.originState == "Other" ? surveyStore.customOriginState : surveyStore.originState,
        gate_lock: surveyStore.originGateCode,
        city: surveyStore.originCity,
        postal_code: surveyStore.originPostalCode,
        country: surveyStore.originCountry,
      })
      .then((data) => {
        rootState.setModules(data.modules);
        navigate(data.modules.next);
      })
      .catch((e) => {
        console.log(e);
        rootState.setError({ message: e.message });
      });
  };

  return (
    <DividingContainer>
      <FieldSet title="Origin Information">
        <VerticalFlexContainer>
          <DropDown
            label="COUNTRY *"
            items={countries}
            onChange={(e) => {
              surveyStore.setOriginCountry(e.value);
            }}
            selected={surveyStore.originCountry}
            isValid={validInput(surveyStore.originCountry)}
          />
          <Input
            label="STREET ADDRESS *"
            value={surveyStore.originStreetAddr}
            handleChange={(e) =>
              surveyStore.setOriginStreetAddr(e.target.value)
            }
            isValid={validInput(surveyStore.originStreetAddr)}
          />
          <Input
            label="HOUSE / APT / UNIT"
            value={surveyStore.originHouseNumber}
            handleChange={(e) =>
              surveyStore.setOriginHouseNumber(e.target.value)
            }
          />
          <Input
            label="GATE CODE"
            value={surveyStore.originGateCode}
            handleChange={(e) => surveyStore.setOriginGateCode(e.target.value)}
          />
          <Input
            label="CITY *"
            value={surveyStore.originCity}
            handleChange={(e) => surveyStore.setOriginCity(e.target.value)}
            isValid={validInput(surveyStore.originCity)}
          />
          <DropDown
            label="STATE/PROVINCE *"
            items={[...stateOptions, 'Other']}
            onChange={(e) => {
              surveyStore.setOriginState(e.value);
            }}
            selected={surveyStore.originState}
          />
          {surveyStore.originState === 'Other' && (
            <Input
              label="ENTER YOUR STATE/PROVINCE *"
              value={surveyStore.customOriginState}
              handleChange={(e) => surveyStore.setCustomOriginState(e.target.value)}
              isValid={validInput(surveyStore.customOriginState)}
            />
          )}
          <Input
            label="ZIP / POSTAL CODE *"
            value={surveyStore.originPostalCode}
            handleChange={(e) =>
              surveyStore.setOriginPostalCode(e.target.value)
            }
            isValid={validZipCode(surveyStore.originPostalCode, addressType)}
          />
        </VerticalFlexContainer>
      </FieldSet>
      <NavContainer>
        <NextButton disabled={!valid} handleSubmit={submitStep} />
      </NavContainer>
    </DividingContainer>
  );
}

export default observer(OriginLocation);
