import { useState, useEffect } from "react";
import { defaultValidator } from "../Utils/validation";

/**
 * @callback validatorCallback
 * @param {string} value
 * @returns {boolean}
 */

/**
 * @param {Object[]} fields
 * @param {string} fields[].value
 * @param {validatorCallback} fields[].validator
 */
export const useValidation = (fields) => {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (
      fields.every(({ value, validator }) => {
        if (validator){
          if(typeof validator === 'function'){
            return validator(value);
          } else if (validator == "noCheck"){
            return true;
          }
        }
        return defaultValidator(value);
      })
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [valid, ...fields.map(field => field.value), ...fields.map(field => field.validator)]);

  return valid;
};