import styled from "styled-components";

const SpinThing = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-bottom: solid 3px #a02200;
  border-left: solid 3px #a02200;
  animation: spin 1s infinite linear;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  z-index: 5;
`;

const BgOVerlay = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  backdrop-filter: blur(3px);
`;

const Text = styled.p`
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 5;
  top: calc(50% + 66px);
  font-size: 32px;
  background-color: white;
`

function Spinner({ text }) {
  return (
    <>
      <BgOVerlay />
      <SpinThing />
      {text && <Text>{text}</Text>}
    </>
  );
}

export default Spinner;
