import styled from "styled-components";

// should take at least the full height of a screen, and separate children (2 ideally)
const DividingContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`;

export default DividingContainer;
