import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import {
  DividingContainer,
  FieldSet,
  Input,
  NavContainer,
} from "./UI";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { NextButton } from "../UI";
import { useRootStore } from "../../Stores/RootStore";
import { useNavigation } from "../../Providers/NavigationProvider";
import { useModules } from "../../Hooks/useModules";

function Contact() {
  const [nextDisabled, setNextDisabled] = useState(true);
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const { navigate } = useNavigation();

  useModules();

  useEffect(() => {
    if (
      validName(surveyStore.fullName) &&
      validEmail(surveyStore.email) &&
      validPhone(surveyStore.phone)
    ) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [
    surveyStore.fullName,
    surveyStore.email,
    surveyStore.phone,
    nextDisabled,
  ]);

  const validName = (name) => {
    return name.trim() !== "";
  };

  const validPhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  const validEmail = (email) => {
    return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/.test(
      email
    );
  };

  const phoneMask = (phoneNumber) => {
    return phoneNumber
      .replace(/\D/g, "")
      .split("")
      .map((ch, idx) => {
        if (idx === 0) {
          return "(" + ch;
        } else if (idx === 3) {
          return ") " + ch;
        } else if (idx === 6) {
          return " - " + ch;
        }
        return ch;
      })
      .join("");
  };

  return (
    <>
      <DividingContainer>
        <FieldSet title="Contact Information">
          <Input
            label="Full Name *"
            value={surveyStore.fullName}
            handleChange={(e) => {
              surveyStore.setFullName(e.target.value);
            }}
            isValid={validName(surveyStore.fullName)}
          />
          <Input
            label="Email *"
            value={surveyStore.email}
            handleChange={(e) => surveyStore.setEmail(e.target.value)}
            type="email"
            isValid={validEmail(surveyStore.email)}
          />
          <Input
            label="Phone *"
            // display value with mask
            value={phoneMask(surveyStore.phone)}
            handleChange={(e) =>
              // strip phone mask before setting state
              surveyStore.setPhone(e.target.value.replace(/\D/g, ""))
            }
            type="tel"
            isValid={validPhone(surveyStore.phone)}
          />
        </FieldSet>

        <NavContainer>
          <NextButton
            disabled={nextDisabled}
            handleNext={() => {
              rootState.api
                .submitContact({
                  uuid: surveyStore.surveyId,
                  step: "contact",
                  full_name: surveyStore.fullName,
                  phone: surveyStore.phone,
                  email: surveyStore.email,
                })
                .then((data) => {
                  rootState.setModules(data.modules);

                  navigate(data.modules.next);
                })
                .catch((e) => {
                  console.log(e);
                  rootState.setError({ message: e.message });
                  if (e.message.includes("email")) {
                    // todo: display message saying email is bad
                    surveyStore.setEmail("");
                  }
                });
            }}
          />
        </NavContainer>
      </DividingContainer>
    </>
  );
}

export default observer(Contact);
