import styled from "styled-components";

const WelcomeContainer = styled.div`
  min-height: calc(100vh - 32px);
  min-height: calc(100svh - 32px);
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: 2fr 2fr 4fr;
`;

export default WelcomeContainer;
