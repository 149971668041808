import React from "react";
import styled from "styled-components";

const FixedTopCenter = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 10;
`;

const Indicator = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.threshold > 50 ? "red" : props.threshold > 30 ? "yellow" : "green"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function MotionIndicator({ rotationRate }) {
  return (
    <FixedTopCenter>
      <Indicator threshold={Math.abs(rotationRate)}>
        {Math.abs(Math.floor(rotationRate))}
      </Indicator>
    </FixedTopCenter>
  );
}
