import styled from "styled-components";
// TODO: figure out how to position this text properly

const HelpText = styled.p`
  font-family: quicksand;
  color: #9e9e9e;
  font-size: 14px;
  text-align: center;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
//  margin-bottom: 16px;
  margin-top: 0;
`;

export default HelpText;
