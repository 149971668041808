export const validZipCode = (zipCode, addressType = "") => {
  if (addressType == "European") return defaultValidator(zipCode);
  let trimmed = zipCode.trim();
  let possiblyCanadian =
    trimmed.length === 6 || (trimmed.length === 7 && trimmed.includes("-"));
  let hasDash = false;
  if (possiblyCanadian) {
    if (trimmed.length === 6) {
      // cannot have a dash
      for (let i = 0; i < trimmed.length; i++) {
        if (trimmed[i] === "-") {
          return false;
        }
      }
    } else {
      // can have dash, but only when i === 3
      for (let i = 0; i < trimmed.length; i++) {
        if (i !== 3 && trimmed[i] === "-") return false;
        else if (i === 3 && trimmed[i] !== "-") return false;
      }
    }

    return true;
  } else {
    if (trimmed.length < 5 || trimmed.length > 10) return false;
    for (let i = 0; i < trimmed.length; i++) {
      if (i < 5 && Number.isNaN(parseInt(trimmed[i]))) {
        return false;
      }
      if (i === 5 && trimmed[i] === "-") {
        hasDash = true;
      }
      if (i > 5 && hasDash && trimmed[i] === "-") {
        return false;
      }
      if (i > 5 && Number.isNaN(parseInt(trimmed[i]))) {
        return false;
      }
    }
    if (hasDash && trimmed.length !== 10) {
      return false;
    }
    if (!hasDash && trimmed.length > 5) {
      return false;
    }
    return true;
  }
};

export const defaultValidator = (data) => {
  return data.trim() !== "";
};
