import React from "react";
import styled from "styled-components";

export const VideoPlaceholder = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  animation: undulation .8s ease  alternate infinite;
  opacity: ${({ loading }) => (loading ? "1" : "0")};
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 2s ease;

  @keyframes undulation {
    0% {
      background-color: #000;
    }
    100% {
      background-color: #333;
    }
  }
`;
