import styled from "styled-components";

const Text = styled.p`
  font-size: 16px;
  padding: 0 16px;
  font-family: quicksand;
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
`;

export default Text