import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";

import UnSizedButton from "./UnSizedButton";

import { useRootStore } from "../../Stores/RootStore";

const Container = styled(Box)`
  // position: absolute;
  // bottom: 60px;
  left: 16%;
  width: 66%;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

function NextButton({
  disabled,
  handleNext,
  handleSubmit,
  btnText = "Continue",
}) {
  const { rootState } = useRootStore();
  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };
  return (
    <Container>
      <UnSizedButton
        onClick={() => {
          if (handleSubmit) {
            handleSubmit().catch((e) => {
              rootState.setError({ message: e.message });
            });
          } else if (handleNext) {
            handleNext();
          }
          scrollToTop();
        }}
        disabled={disabled}
      >
        {btnText}
      </UnSizedButton>
    </Container>
  );
}

export default NextButton;
