import React, { createContext, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";

import { useRootStore } from "../Stores/RootStore";

const navigationContext = createContext();

export const NavigationProvider = observer(({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { rootState } = useRootStore();

  const navigate = (path) => {
    // if not, redirect to home
    const qParams = new URLSearchParams(location.search);
    const modules = rootState.modules;
    const module = modules.available_modules.find((m) => m === path);
    if (module || path === "/video") {
      history.push(path + "?" + qParams.toString());
    } else {
      history.push("?" + qParams.toString());
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/video") {
      navigate("/rooms");
    }
  }, []);

  return (
    <navigationContext.Provider
      value={{
        navigate: navigate,
      }}
    >
      {children}
    </navigationContext.Provider>
  );
});

export const useNavigation = () => useContext(navigationContext);
