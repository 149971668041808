import styled from "styled-components";

const HeadingOne = styled.h1`
  font-size: 48px;
  font-weight: normal;
  font-family: montserrat;
  flex: 1;
`;

export default HeadingOne
