import React from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { PaddedIcon } from "../../Svg/Components/PaddedIcon";

const StyledButton = styled(Button)`
  font-size: 8px;
  width: 100%;
  height: 100%;
  border: solid 1px #ddd;
  text-align: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ complete }) => (complete ? "#1976d2" : "#eee")};
  color: ${({ complete }) => (complete ? "#fff" : "#000")};
  box-shadow: 0 4px 12px 2px #ddd;
  padding: 0.5rem;
  margin: 0;

  :hover {
    background-color: ${({ complete }) => (complete ? "#1976d2" : "#eee")};
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  font-size: 6em;
  width: 100%;
  max-width: 150px;
  height: 100%;
  ${({ complete }) => {
    if (complete) {
      return `
        fill: white;
        stroke: white;
      `;
    }
  }}
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.header`
  font-size: 1em;
  font-weight: bold;
  font-family: montserrat;
  text-align: center;
`;

const Text = styled.p`
  text-transform: initial;
  font-size: 1em;
  margin: 0;
  font-family: montserrat;
`;

function GridButton({
  headerText,
  bodyText,
  handleClick,
  complete,
  icon,
  className,
}) {
  // fontawesome icons are objects, custom icons are react function components
  const isFontAwesome = typeof icon !== "string" && typeof icon !== "function";
  // call icon as a react component if icon is not from font awesome and set innerHTML if icon is svg string
  const IconComponent = () =>
    typeof icon === "function" ? (
      icon()
    ) : (
      <span dangerouslySetInnerHTML={{ __html: icon }} />
    );

  return (
    <StyledButton
      complete={complete}
      onClick={handleClick}
      className={className}
    >
      {icon && (
        <Icon complete={complete}>
          <PaddedIcon>
            {isFontAwesome ? (
              <FontAwesomeIcon icon={icon} />
            ) : (
              <IconComponent />
            )}
          </PaddedIcon>
        </Icon>
      )}
      <Content>
        <Header>{headerText}</Header>
        {bodyText && <Text>{bodyText}</Text>}
      </Content>
    </StyledButton>
  );
}

export default GridButton;
