import React, { useEffect, useState } from "react";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledInput = styled.input`
  padding: 8px;
  width: 100%;
  font-family: quicksand;
  height: 60px;
  font-size: 20px;
`;

const Label = styled.label`
  position: relative;
  width: 100%;
  padding-bottom: 14px;
  font-size: 16px;
`;

const LabelText = styled.div`
  color: #1976d2;
  font-family: inter;
  font-size: 14px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 20px;
  right: 16px;
  bottom: 38px;
`;

const ValidIcon = styled(Icon)`
  color: green;
`;

const InvalidIcon = styled(Icon)`
  color: red;
`;

function Input({ label, value, handleChange, isValid, placeholder="", type = "text" }) {
  const [hadInput, setHadInput] = useState(false);

  useEffect(() => {
    if (value) {
      setHadInput(true);
    }
  });

  return (
    <Label htmlFor={label}>
      <LabelText>{label}</LabelText>
      <StyledInput
        id={label}
        value={value}
        onChange={(e) => {
          setHadInput(true);
          handleChange(e);
        }}
        type={type}
        placeholder={placeholder}
        // invalid={!isValid && !hadInput}
      />
      {hadInput && <ValidationIcon isValid={isValid} />}
    </Label>
  );
}

function ValidationIcon({ isValid }) {
  if (isValid === true) return <ValidIcon icon={faCheck} />;
  else if (isValid === false) return <InvalidIcon icon={faX} />;
  else return null;
}

export default Input;
