import { useState, useEffect, useCallback, useReducer } from "react";
import { useRootStore } from "../Stores/RootStore";

export const useGyro = () => {
  const { rootState } = useRootStore();
  const [rotationRate, setRotationRate] = useState(0);

  const [highestVal, setHighestVal] = useState(-Infinity);
  const [orientation, dispatch] = useReducer(orientationReducer, {
    arcAngle: 0,
    highest: -Infinity,
    lowest: 180,
  });

  const reset = useCallback(() => {
    dispatch({type: 'reset'});
  }, [])

  const handleDeviceMotion = useCallback((event) => {
    setRotationRate(event.rotationRate.beta);
  }, []);

  const handleDeviceOrientation = useCallback((event) => {
    dispatch({ type: "setAngle", payload: Math.round(event.beta )});
  }, []);

  useEffect(() => {
    if (rootState.recorder && orientation.arcAngle > orientation.highest) {
      dispatch({ type: "setHighest", payload: Math.round(orientation.arcAngle )});
    } 
    if (rootState.recorder && orientation.arcAngle && orientation.arcAngle < orientation.lowest) {
      dispatch({ type: "setLowest", payload: Math.round(orientation.arcAngle )});
    }   }, [orientation.arcAngle]);

  useEffect(() => {
    if (Math.abs(rotationRate) > highestVal) {
      setHighestVal(Math.abs(rotationRate));
    }
  }, [rotationRate, highestVal]);

  useEffect(() => {
    if (rootState.gyroEnabled) {
      window.addEventListener("devicemotion", handleDeviceMotion);
      window.addEventListener("deviceorientation", handleDeviceOrientation);
    }

    return () => {
      window.removeEventListener("devicemotion", handleDeviceMotion);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, [rootState.gyroEnabled]);

  return [Math.abs(Math.round(rotationRate)), orientation, reset];
};

function orientationReducer(state, action) {
  switch (action.type) {
    case "setAngle":
      return { ...state, arcAngle: action.payload };
    case "setHighest":
      return { ...state, highest: action.payload };
    case "setLowest":
      return { ...state, lowest: action.payload };
    case 'reset':
      return {
    arcAngle: 0,
    highest: -Infinity,
    lowest: 180,
  }
    default:
      return state;
  }
}
