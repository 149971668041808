import { surveyStoreContext } from "../Stores/SurveyStore";

export const SurveyStateProvider = ({ surveyState, children }) => {
  const stores = {
    surveyState,
  };
  return (
    <surveyStoreContext.Provider value={stores}>
      {children}
    </surveyStoreContext.Provider>
  );
};
