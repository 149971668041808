import React from "react";

import { GridButton } from ".";

function RoomButton({ room, icon, handleClick, complete, className }) {
  return (
    <GridButton
      className={className}
      headerText={room}
      icon={icon}
      handleClick={handleClick}
      complete={complete}
    />
  );
}

export default RoomButton;
