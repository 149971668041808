import React from "react";
import { observer } from "mobx-react";

import { useNavigation } from "../../Providers/NavigationProvider";
import { useRootStore } from "../../Stores/RootStore";

import { Header, NextButton } from "../UI";
import { WelcomeContainer, HeadingOne, WelcomeText } from "./UI";
import { useModules } from "../../Hooks/useModules";

function Welcome() {
  const { rootState } = useRootStore();
  const { navigate } = useNavigation();

  useModules();

  return (
    <>
      <WelcomeContainer>
        <Header />
        <HeadingOne>Welcome</HeadingOne>
        <WelcomeText>
          Any time is convenient to use our technology for accurate results.
        </WelcomeText>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
            marginBottom: "32px",
          }}
        >
          <NextButton
            btnText="Start"
            handleNext={() => {
              navigate(rootState.modules.next);
            }}
          />
        </div>
      </WelcomeContainer>
    </>
  );
}

export default observer(Welcome);
