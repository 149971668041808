import { Button } from "@mui/material";
import styled from "styled-components";

const UnSizedButton = styled(Button)`
  background-color: #a02200;
  color: white;
  width: 100%;
  border-radius: 0;
  font-family: montserrat;

  :hover {
    background-color: #a02200;
  }

  :disabled {
    background-color: #9e9e9e;
    color: #fff;
  }
`;

export default UnSizedButton;
