import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import { useSurveyStore } from "../../Stores/SurveyStore";
import {
  DividingContainer,
  FieldSet,
  Input,
  DropDown,
  NavContainer,
  VerticalFlexContainer,
} from "./UI";
import { NextButton } from "../UI";
import {
  validZipCode,
  defaultValidator as validInput,
} from "../../Utils/validation";
import { states } from "../../Constants/states";
import { useValidation } from "../../Hooks/useValidation";
import { useRootStore } from "../../Stores/RootStore";
import { useModules } from "../../Hooks/useModules";
import { useNavigation } from "../../Providers/NavigationProvider";

function DestinationLocation() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const countries = Object.keys(states);
  const [stateOptions, setStateOptions] = useState([]);
  useModules();
  const { navigate } = useNavigation();

  useEffect(() => {
    if (surveyStore.destinationCountry) {
      if (surveyStore.destinationState) {
        if (
          !states[surveyStore.destinationCountry]
            .map((s) => s.name)
            .includes(surveyStore.destinationState)
        ) {
          surveyStore.setDestinationState("");
        }
      }
      setStateOptions(
        states[surveyStore.destinationCountry].map((s) => s.name)
      );
    }
  }, [surveyStore.destinationCountry, surveyStore.destinationState]);

  const valid = useValidation([
    { value: surveyStore.destinationCountry },
    { value: surveyStore.destinationStreetAddr },
    { value: surveyStore.destinationCity },
    { value: surveyStore.destinationState },
    { value: surveyStore.destinationPostalCode, validator: validZipCode },
  ]);

  const submitStep = async () => {
    rootState.api
      .submitDestinationLocation({
        uuid: surveyStore.surveyId,
        step: "destination_location",
        street_address: surveyStore.destinationStreetAddr,
        state: surveyStore.destinationState,
        postal_code: surveyStore.destinationPostalCode,
        house: surveyStore.destinationHouseNumber,
        gate_lock: surveyStore.destinationGateCode,
        city: surveyStore.destinationCity,
        country: surveyStore.destinationCountry,
      })
      .then((data) => {
        rootState.setModules(data.modules);
        navigate(data.modules.next);
      })
      .catch((e) => {
        rootState.setError({ message: e.message });
      });
  };

  return (
    <DividingContainer>
      <FieldSet title="Destination Information">
        <VerticalFlexContainer>
          <DropDown
            label="COUNTRY *"
            items={countries}
            onChange={(e) => {
              surveyStore.setDestinationCountry(e.value);
            }}
            selected={surveyStore.destinationCountry}
            isValid={validInput(surveyStore.destinationCountry)}
          />
          <Input
            label="STREET ADDRESS *"
            value={surveyStore.destinationStreetAddr}
            handleChange={(e) =>
              surveyStore.setDestinationStreetAddr(e.target.value)
            }
            isValid={validInput(surveyStore.destinationStreetAddr)}
          />
          <Input
            label="HOUSE / APT / UNIT"
            value={surveyStore.destinationHouseNumber}
            handleChange={(e) =>
              surveyStore.setDestinationHouseNumber(e.target.value)
            }
          />
          <Input
            label="GATE CODE"
            value={surveyStore.destinationGateCode}
            handleChange={(e) =>
              surveyStore.setDestinationGateCode(e.target.value)
            }
          />
          <Input
            label="CITY *"
            value={surveyStore.destinationCity}
            handleChange={(e) => surveyStore.setDestinationCity(e.target.value)}
            isValid={validInput(surveyStore.destinationCity)}
          />
          <DropDown
            label="STATE/PROVINCE *"
            items={stateOptions}
            onChange={(e) => {
              surveyStore.setDestinationState(e.value);
            }}
            selected={surveyStore.destinationState}
          />
          <Input
            label="ZIP / POSTAL CODE *"
            value={surveyStore.destinationPostalCode}
            handleChange={(e) =>
              surveyStore.setDestinationPostalCode(e.target.value)
            }
            isValid={validZipCode(surveyStore.destinationPostalCode)}
          />
        </VerticalFlexContainer>
      </FieldSet>
      <NavContainer>
        <NextButton handleSubmit={submitStep} disabled={!valid} />
      </NavContainer>
    </DividingContainer>
  );
}

export default observer(DestinationLocation);
