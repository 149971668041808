import React from "react";
import styled from "styled-components";

export const StyledVideo = styled.video`
  position: fixed;
  inset: 0;
  height: 100%;
  object-fit: cover;
  margin-bottom: -8px;
`;
