import React, { useEffect } from "react";
import { observer } from "mobx-react";

import { HeadingTwo, NavContainer, DividingContainer } from "./UI";
import { Text } from "../UI";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { useRootStore } from "../../Stores/RootStore";

function SurveyComplete() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();

  const submitStep = async () => {
    rootState.api.submitFinalize({ uuid: surveyStore.surveyId }).catch((e) => {
      rootState.setError({ message: e.message });
    });
  };

  useEffect(() => {
    if (surveyStore.surveyId) submitStep();
  }, [surveyStore.surveyId]);

  return (
    <DividingContainer>
      <img width="65%" src="/logo.svg" alt="Virtual Moving Technologies Logo" />
      <HeadingTwo>YOU'RE ALL SET!</HeadingTwo>
      <Text>
        A team member will be in touch with you shortly so this can be the most
        relaxing move you've ever done.
      </Text>
      <NavContainer />
    </DividingContainer>
  );
}

export default observer(SurveyComplete);
