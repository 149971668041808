import styled from "styled-components";

const ItemList = styled.div`
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-auto-rows: 1fr;
  gap: .75rem;
`;

export default ItemList;
