import { Box } from "@mui/material";
import styled from "styled-components";

const VerticalFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  gap: 16px;
`;

export default VerticalFlexContainer;
