import React from "react";
import { faCircle, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledButton = styled.button`
  position: fixed;
  width: 64px;
  height: 64px;
  left: calc(50% - 32px);
  top: calc(100% - 90px);
  font-size: 32px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a02200;
  color: #333;
  z-index: 3;
`;

function RecordStopButton({ handleClick, isRecording }) {
  return (
    <StyledButton onClick={handleClick}>
      {isRecording ? (
        <FontAwesomeIcon icon={faStop} />
      ) : (
        <FontAwesomeIcon icon={faCircle} />
      )}
    </StyledButton>
  );
}

export default RecordStopButton;
