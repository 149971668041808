import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useRootStore } from "../../Stores/RootStore";

const ErrorCardDisplay = styled.div`
  background-color: #a02200;
  color: #ddd;
  padding: 1rem 0;
  width: 100vw;
  text-align: center;
  border-radius: 0 0 1rem 1rem;
  position: fixed;
  top: 0;
  z-index: 5;
  opacity: ${({ dismissed }) => (dismissed ? 0 : 1)};
  transition: opacity 1s ease;
`;

function ErrorCard({}) {
  const { rootState } = useRootStore();
  const timeoutRef = useRef(null);
  const transitionRef = useRef(null);
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    transitionRef.current = setTimeout(() => {
      setSeen(true);
    }, 5000);
    timeoutRef.current = setTimeout(() => {
      rootState.setError(null);
      setSeen(false);
    }, 6000);

    let timeout = timeoutRef.current;
    let timeout2 = transitionRef.current;

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, [rootState.error]);

  if (!rootState.error) {
    return null;
  }

  let { message } = rootState.error;
  if (!message) {
    message = "There was an error";
  }

  return (
    <ErrorCardDisplay
      dismissed={seen}
      onClick={() => {
        rootState.setError(null);
      }}
    >
      Error: {message}
    </ErrorCardDisplay>
  );
}

export default observer(ErrorCard);
