import React from "react";
import { observer } from "mobx-react";

import { useRootStore } from "../../Stores/RootStore";
import { useSurveyStore } from "../../Stores/SurveyStore";
import HowManyRooms from "./HowManyRooms";

function RoomCount() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();

  return (
    <HowManyRooms
      roomCount={surveyStore.howManyRooms}
      setRoomCount={(val) => surveyStore.setHowManyRooms(val)}
      handleNext={() => {
        rootState.api.submitRoomCount({
          room_count: surveyStore.howManyRooms,
          uuid: surveyStore.surveyId,
        });
      }}
    />
  );
}

export default observer(RoomCount);
