// Footer is not used any more
import React from "react";
import { AppBar, Box } from "@mui/material";
import styled from "styled-components";

import { useSurveyStore } from "../../Stores/SurveyStore";
import FooterButton from "./FooterButton";
import { SaveAndProceed } from "../Video";
import { observer } from "mobx-react";

const styles /** // TODO: replace with styled-components */ = {
  footer: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#a02200",
    height: 56,
    display: "flex",
    justifyContent: "center",
  },
  arrowBtns: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    alignItems: "center",
  },
  saveBtn: {
    display: "flex",
    justifyContent: "center",
    padding: "16px",
    alignItems: "center",
  },
};

const StyledFooter = styled(AppBar)`
  top: auto;
  bottom: 0;
  background-color: #a02200;
  height: 56;
  display: flex;
  justify-content: center;
`;

const SaveButton = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 16px;
  align-items: center;
`;

function Footer() {
  const surveyState = useSurveyStore();

  return (
    <StyledFooter position="fixed">
      {surveyState.rootStore.inVideoFlow ? (
        ""
      ) : (
        <>
          {surveyState.roomOpen ? (
            <SaveButton>
              <SaveAndProceed />
            </SaveButton>
          ) : (
            <Box
              sx={{
                ...styles.arrowBtns,
                flexDirection:
                  surveyState.currentStep === 0 ? "row-reverse" : "row",
              }}
            >
              {surveyState.currentStep !== 0 && (
                <FooterButton isBackButton={true} />
              )}
              <FooterButton />
            </Box>
          )}
        </>
      )}
    </StyledFooter>
  );
}

export default observer(Footer);
