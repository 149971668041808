import React, { useEffect, useRef, useState } from "react";
import { faPause, faPlay, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import styled from "styled-components";

import { useSurveyStore } from "../../Stores/SurveyStore";
import { SaveButton } from "../UI";

const Container = styled(Box)`
  position: relative;
  height: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  inset: 0;
`;

function PreviewVideo({ cancelCallback, saveCallback }) {
  const previewRef = useRef(null);
  const surveyStore = useSurveyStore();

  useEffect(() => {
    let ref = previewRef.current;
    if (ref) {
      const currentRoom = surveyStore.currentRoom;
      previewRef.current.src = currentRoom.streamSrc;
    }

    return () => {
      if (ref) {
        // reset src on video element
        ref.src = null;
      }
    };
  }, [surveyStore]);

  const togglePause = () => {
    if (previewRef.current) {
      if (previewRef.current.paused) {
        previewRef.current.play();
      } else {
        previewRef.current.pause();
      }
    }
  };

  return (
    <Container>
      <Video ref={previewRef} playsInline loop autoPlay></Video>
      <PlayPauseBtn handleClick={togglePause} />
      <CancelButton handleClick={cancelCallback} />
      <SaveButton onClick={saveCallback}>SAVE AND UPLOAD</SaveButton>
    </Container>
  );
}

const PlayPauseButton = styled.button`
  position: fixed;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  width: 32px;
  height: 32px;
  background-color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  left: calc(100% - 40px);
`;

const CancelBtn = styled(PlayPauseButton)`
  background-color: #a02200;
  left: 8px;
`;

function PlayPauseBtn({ handleClick }) {
  const [playing, setPlaying] = useState(true);

  return (
    <PlayPauseButton
      onClick={() => {
        setPlaying(!playing);
        handleClick();
      }}
    >
      {playing ? (
        <FontAwesomeIcon icon={faPause} />
      ) : (
        <FontAwesomeIcon icon={faPlay} />
      )}
    </PlayPauseButton>
  );
}

function CancelButton({ handleClick }) {
  return (
    <CancelBtn onClick={handleClick}>
      <FontAwesomeIcon icon={faX} />
    </CancelBtn>
  );
}

export default PreviewVideo;
