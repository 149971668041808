import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import { Prompt } from "./UI";

const PromptHeader = styled.header`
  font-size: 24px;
  margin-bottom: 16px;
  font-family: montserrat;
`;

const RoomInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
`;

function HowManyRooms({ handleNext, roomCount, setRoomCount }) {
  const makePositive = (val) => {
    if (val < 0) {
      return 0;
    } else {
      return val;
    }
  };

  return (
    <div>
      <Prompt
        hideCloseBtn="true"
        singleBtn
        leftBtnText="Continue"
        leftBtnClick={handleNext}
      >
        <PromptHeader>How many rooms are you moving?</PromptHeader>
        <RoomInput
          type="number"
          value={roomCount}
          onChange={(e) => setRoomCount(makePositive(e.target.value))}
        />
      </Prompt>
    </div>
  );
}

export default observer(HowManyRooms);
