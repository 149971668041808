import styled from "styled-components";

const WelcomeText = styled.p`
  font-size: 16px;
  text-align: center;
  padding: 0 16px;
  font-family: quicksand;
  flex: 1;
  max-width: 285px;
  
  @media (min-width: 550px) {
    font-size: 20px;
  }
`;

export default WelcomeText;
