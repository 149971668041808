import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";

const Group = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

function PanelButtonGroup({ children }) {
    return <Group>{children}</Group>;
}

export default PanelButtonGroup;
