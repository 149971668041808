import React, { useState, useReducer } from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";

import {
  FieldSet,
  Prompt,
  SpecialItemsButton,
  GridList,
  NavContainer,
  StretchedContainer,
} from "./UI";
import { NextButton, SizedButton, Instructions } from "../UI";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { observer } from "mobx-react";
import { useRootStore } from "../../Stores/RootStore";
import { useModules } from "../../Hooks/useModules";
import { useNavigation } from "../../Providers/NavigationProvider";

function SpecialItems() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const [currentItem, setCurrentItem] = useState(null); // item name, like "Firearms", or "Small Vehicle" used to get item from item map
  const items = Object.keys(surveyStore.specialItems ?? []);
  const { navigate } = useNavigation();
  useModules();

  const answerQuestion = (item, questionIndex, answer, complete = false) => {
    const questions = item.questions.map((q, qI) => {
      if (questionIndex === qI) {
        return {
          ...q,
          question: q.question,
          answer,
          choice: q.choice || false,
          formatted: q.formatted,
          cat: q.cat,
        };
      }
      return q;
    });
    surveyStore.setSpecialItem({
      ...item,
      complete,
      questions,
    });
  };

  const submitStep = async () => {
    const special_items = Object.keys(surveyStore.specialItems).map((key) => {
      let item = surveyStore.specialItems[key];
      let answeredQuestions = item.questions.filter((q) => q.answer);
      return {
        complete: item.complete,
        cat: item.cat,
        item: item.item,
        questions: answeredQuestions,
        id: item.id,
      };
    });

    rootState.api
      .submitSpecialItems({
        uuid: surveyStore.surveyId,
        special_items: special_items
          .filter((i) => i.complete)
          .map((i) => ({
            name: i.item,
            item: i.item,
            questions: i.questions,
            cat: i.cat,
            id: i.id,
          })),
      })
      .then((data) => {
        rootState.setModules(data.modules);
        navigate(data.modules.next);
      });
  };
  return (
    <>
      <StretchedContainer>
        <FieldSet title="Special Items">
          <Instructions>
            Select items moving from anywhere in your home.
          </Instructions>
          <GridList>
            {items.map((itemKey) => {
              let item = surveyStore.specialItems[itemKey];
              return (
                <SpecialItemsButton
                  key={itemKey}
                  room={itemKey}
                  icon={item.icon}
                  complete={item.complete}
                  handleClick={() => {
                    setCurrentItem(itemKey);
                  }}
                />
              );
            })}
          </GridList>
        </FieldSet>
        <NavContainer>
          <NextButton handleSubmit={submitStep} to="/destination-location" />
        </NavContainer>
      </StretchedContainer>
      {currentItem && (
        <SpecialItemPrompt
          item={surveyStore.specialItems[currentItem]}
          closePrompt={() => setCurrentItem(null)}
          onAnswer={answerQuestion}
        />
      )}
    </>
  );
}

export default observer(SpecialItems);

const PromptHeader = styled.header`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const PromptQuestion = styled.p`
  text-align: center;
`;

const PromptAnswer = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
`;

const SpecialItemPrompt = ({ item, closePrompt, onAnswer }) => {
  const questionReducer = (state, action) => {
    switch (action.type) {
      case "add-follow-up":
        // add follow up questions, next in line
        // need to make sure i dont add a question more than once
        const newQuestions = item.questions.slice(); // shallow copy of questions to splice into
        // convert questions to string[] to check for new questions before inserting
        const questionParts = item.questions.map((q) => q.question);

        action.payload.questions.forEach((q) => {
          if (!questionParts.includes(q.question)) {
            newQuestions.splice(state.questionIndex + 1, 0, q);
          }
          item.questions = newQuestions; // include new questions before calling setSpecialItem()
        });

        if (state.questionIndex === state.questions.length - 1) {
          onAnswer(item, state.questionIndex, action.payload.answer);
          return {
            ...state,
            questions: newQuestions,
            questionIndex: state.questionIndex + 1,
          };
        }
        onAnswer(item, state.questionIndex, action.payload.answer);
        return state;

      case "next-question":
        // check if answer holds dimensions object
        let ans = action.payload.answer;
        if (
          typeof action.payload.answer === "object" &&
          action.payload.answer.hasOwnProperty("w") &&
          action.payload.answer.hasOwnProperty("h") &&
          action.payload.answer.hasOwnProperty("l")
        ) {
          // convert dimensions to string
          let { w, h, l } = action.payload.answer;
          ans = `W: ${w} H: ${h} L: ${l}`;
        }
        if (state.questions.length - 1 === state.questionIndex) {
          onAnswer(item, state.questionIndex, ans, true);
          closePrompt();
          return state;
        }
        onAnswer(item, state.questionIndex, ans);
        return { ...state, questionIndex: state.questionIndex + 1 };
      default:
        return state;
    }
  };
  const [questionState, dispatch] = useReducer(questionReducer, {
    questionIndex: 0,
    questions: item.questions,
  });

  const [answer, setAnswer] = useState("");
  const [dimensions, setDimensions] = useState({ l: null, w: null, h: null });
  const question = questionState.questions[questionState.questionIndex];

  const buttonText =
    questionState.questionIndex !== questionState.questions.length - 1
      ? "Next"
      : "Done";

  const handleNext = (answer) => {
    // pass answer up and move to the next question
    dispatch({ type: "next-question", payload: { answer } });
    setAnswer("");
  };

  const isDimensionQuestion = question.question.includes("dimension");

  return (
    <Prompt closeBtnClick={closePrompt} noButtons>
      {questionState.questions[questionState.questionIndex].answers ? (
        <Choice
          itemName={item.item}
          question={question.question}
          options={question.answers}
          dispatch={dispatch}
          handleNext={handleNext}
        />
      ) : (
        <Question
          itemName={item.item}
          question={question.question}
          answer={isDimensionQuestion ? dimensions : answer}
          onChange={isDimensionQuestion ? setDimensions : setAnswer}
          buttonText={buttonText}
          handleNext={handleNext}
        />
      )}
    </Prompt>
  );
};

const DimensionsInputWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
`;
const DimensionsInput = styled(TextField)`
  padding: 0;
  background-color: #ddd;
  height: unset;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
`;

const Dimensions = ({ value, onChange }) => {
  const { l, w, h } = value;

  const forceNumber = (val) => {
    if (Number.isNaN(parseInt(val))) {
      // set NaNs to null
      return null;
    } else {
      if (parseInt(val) < 0) {
        // turn negatives to positive
        return -parseInt(val);
      }
      // return number
      return parseInt(val);
    }
  };

  return (
    <DimensionsInputWrapper>
      <DimensionsInput
        className="dimension-input"
        value={forceNumber(l)}
        inputMode="tel"
        pattern="[0-9]*"
        type="tel"
        onChange={(e) => {
          onChange({ h, w, l: forceNumber(e.target.value) });
        }}
      />
      X{" "}
      <DimensionsInput
        className="dimension-input"
        value={forceNumber(w)}
        inputMode="tel"
        pattern="[0-9]*"
        type="tel"
        onChange={(e) => {
          onChange({ l, h, w: forceNumber(e.target.value) });
        }}
      />
      X{" "}
      <DimensionsInput
        className="dimension-input"
        value={forceNumber(h)}
        inputMode="tel"
        pattern="[0-9]*"
        type="tel"
        onChange={(e) => {
          onChange({ l, w, h: forceNumber(e.target.value) });
        }}
      />
    </DimensionsInputWrapper>
  );
};

const Question = ({
  itemName,
  question,
  answer,
  buttonText,
  handleNext,
  onChange,
}) => {
  const isDimensionQuestion = question.includes("dimensions");
  return (
    <>
      <PromptHeader>{itemName}</PromptHeader>

      <PromptQuestion>
        {isDimensionQuestion ? "Dimensions in inches:" : question}
      </PromptQuestion>
      {isDimensionQuestion ? (
        <Dimensions value={answer} onChange={onChange} />
      ) : (
        <PromptAnswer
          autoFocus
          value={answer}
          onChange={(e) => onChange(e.target.value)}
          variant="standard"
        />
      )}

      <SizedButton
        onClick={() => {
          handleNext(answer);
        }}
        disabled={isDimensionQuestion && (!answer.w || !answer.h || !answer.l)}
      >
        {buttonText}
      </SizedButton>
    </>
  );
};

function Choice({ itemName, question, options, dispatch, handleNext }) {
  const [optionA, optionB] = options;
  return (
    <>
      <PromptHeader>{itemName}</PromptHeader>

      <PromptQuestion>{question}</PromptQuestion>

      {/** space these buttons out a little bit **/}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          width: "100%",
        }}
      >
        <SizedButton
          onClick={() => {
            if (optionA.questions) {
              dispatch({
                type: "add-follow-up",
                payload: {
                  questions: optionA.questions,
                  answer: optionA.answer,
                },
              });
            } else {
              handleNext(optionA.answer);
            }
          }}
        >
          {optionA.answer}
        </SizedButton>
        <SizedButton
          onClick={() => {
            if (optionB.questions) {
              dispatch({
                type: "add-follow-up",
                payload: {
                  questions: optionB.questions,
                  answer: optionB.answer,
                },
              });
            } else {
              handleNext(optionB.answer);
            }
          }}
        >
          {optionB.answer}
        </SizedButton>
      </div>
    </>
  );
}
