import React from "react";
import styled from "styled-components";

export const CancelBtn = styled.button`
  position: fixed;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a02200;
  top: 24px;
  left: 24px;
  z-index: 1;
`;
