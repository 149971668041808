import React from "react";
import { AppBar } from "@mui/material";
import styled from "styled-components";

const Image = styled.img`
  height: 100%;
`;

const StyledHeader = styled(AppBar)`
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Header() {
  return (
    <StyledHeader elevation={0} position="relative">
      <Image
        src="logo.svg"
        alt="Virtual Moving Technologies"
      />
    </StyledHeader>
  );
}

export default Header;
