import React, { useEffect } from "react";

import { useRootStore } from "../Stores/RootStore";
import { useSurveyStore } from "../Stores/SurveyStore";

export const useModules = () => {
  const { rootState } = useRootStore();
  const surveyStore = useSurveyStore();

  let currentModule = window.location.pathname.slice(1).replace(/-/, "_");
  if (!currentModule) {
    currentModule = "init";
  }
  useEffect(() => {
    if (
      surveyStore.surveyId &&
      (rootState.modules.current !== window.location.pathname ||
        window.location.pathname === "/")
    ) {
      rootState.api
        .getCurrentStep({
          step: currentModule,
          uuid: surveyStore.surveyId,
        })
        .then((data) => {
          rootState.setModules(data);
        });
    }
  }, [rootState.modules.current, surveyStore.surveyId]);
};
