import styled from "styled-components";
import { SizedButton } from ".";

const SaveButton = styled(SizedButton)`
  position: fixed;
  left: 17%;
  bottom: 10%;
  width: 66%;
`;

export default SaveButton;
