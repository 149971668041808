import React from "react";

function Crosshairs({ angle }) {
  const rad = (deg) => (deg * Math.PI) / 180;

  const verticalShift = (angle) => {
    return (Math.cos(rad(20 + angle)) / 2) * 100;
  };

  return (
    <svg
      viewBox="0 0 20 20"
      style={{
        position: "fixed",
        opacity: .5,
        zIndex: 100,
        width: "200px",
        height: "200px",
        left: "calc(50% - 100px)",
        top: `calc(50% - 100px - ${verticalShift(angle)}vh)`,
      }}
    >
      <path fill="none" stroke="#000" strokeWidth="2" d="M0 3,L 0 0, L 3 0" />
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="M0 17,L 0 20, L 3 20"
      />
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="M17 0,L 20 0, L 20 3"
      />
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="M20 17,L 20 20, L 17 20"
      />
    </svg>
  );
}

export default Crosshairs;
