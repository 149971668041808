import React, { useState, useEffect } from "react";

import { useSurveyStore } from "../../Stores/SurveyStore";
import {
  DividingContainer,
  FieldSet,
  Input,
  VerticalFlexContainer,
  NavContainer,
  Prompt,
} from "./UI";
import { NextButton } from "../UI";
import { observer } from "mobx-react";
import { useRootStore } from "../../Stores/RootStore";
import styled from "styled-components";

const PromptText = styled.p`
  font-family: montserrat;
  text-align: center;
`;

function ExtraStops() {
  const [nextDisabled, setNextDisabled] = useState(true);
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const [hasExtraStop, setHasExtraStop] = useState(false);

  const validInput = (data) => {
    return data.trim() !== "";
  };

  useEffect(() => {
    if (
      validInput(surveyStore.extraStopStreetAddr) &&
      validInput(surveyStore.extraStopHouseNumber) &&
      validInput(surveyStore.extraStopGateCode) &&
      validInput(surveyStore.extraStopCity) &&
      validInput(surveyStore.extraStopCountry)
    ) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [
    nextDisabled,
    surveyStore.extraStopStreetAddr,
    surveyStore.extraStopHouseNumber,
    surveyStore.extraStopGateCode,
    surveyStore.extraStopCity,
    surveyStore.extraStopCountry,
  ]);

  const submitStep = async () => {
    const response = await fetch(
      "https://video-processing-qa.virtualmovingtechnologies.com/process.php?step=extra_stop",
      {
        method: "post",
        body: JSON.stringify({
          street_address: surveyStore.extraStopStreetAddr,
          house: surveyStore.extraStopHouseNumber,
          gate_lock: surveyStore.extraStopGateCode,
          city: surveyStore.extraStopCity,
          country: surveyStore.extraStopCountry,
        }),
      }
    );

    if (!response.ok) {
      throw Error("failed to submit extra stop data");
    }
  };

  return (
    <DividingContainer>
      <FieldSet title="Addition Location">
        {hasExtraStop ? (
          <VerticalFlexContainer>
            <Input
              label="STREET ADDRESS"
              value={surveyStore.extraStopStreetAddr}
              handleChange={(e) =>
                surveyStore.setExtraStopStreetAddr(e.target.value)
              }
              isValid={validInput(surveyStore.extraStopStreetAddr)}
            />
            <Input
              label="HOUSE / APT / UNIT"
              value={surveyStore.extraStopHouseNumber}
              handleChange={(e) =>
                surveyStore.setExtraStopHouseNumber(e.target.value)
              }
              isValid={validInput(surveyStore.extraStopHouseNumber)}
            />
            <Input
              label="GATE CODE"
              value={surveyStore.extraStopGateCode}
              handleChange={(e) =>
                surveyStore.setExtraStopGateCode(e.target.value)
              }
              isValid={validInput(surveyStore.extraStopGateCode)}
            />
            <Input
              label="TOWN / CITY"
              value={surveyStore.extraStopCity}
              handleChange={(e) => surveyStore.setExtraStopCity(e.target.value)}
              isValid={validInput(surveyStore.extraStopCity)}
            />
            <Input
              label="COUNTRY"
              value={surveyStore.extraStopCountry}
              handleChange={(e) =>
                surveyStore.setExtraStopCountry(e.target.value)
              }
              isValid={validInput(surveyStore.extraStopCountry)}
            />
          </VerticalFlexContainer>
        ) : (
          <Prompt
            leftBtnClick={() => setHasExtraStop(true)}
            rightBtnClick={() =>
              rootState.setCurrentStep(rootState.currentStep + 1)
            }
            closeBtnClick={() =>
              rootState.setCurrentStep(rootState.currentStep + 1)
            }
          >
            <PromptText>
              Do you have any extra stops for pickup/delivery?
            </PromptText>
          </Prompt>
        )}
      </FieldSet>
      <NavContainer>
        <NextButton handleSubmit={submitStep} disabled={nextDisabled} />
      </NavContainer>
    </DividingContainer>
  );
}

export default observer(ExtraStops);
