import React from "react";
import { observer } from "mobx-react";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

import { useSurveyStore } from "../../Stores/SurveyStore";
import { useRootStore } from "../../Stores/RootStore";
import "../../Css/customStyles.css";
import { HeadingTwo } from "./UI";
import { NextButton } from "../UI";

const ButtonContainer = styled.span`
  display: flex;
  justify-content: center;
`;

function MovingDate() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();

  const inTheFuture = (date) => {
    let today = new Date();
    return date > today;
  };

  const toDateString = (date) => {
    let d = new Date(date);
    return `${d.getFullYear()}-${
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
    }-${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}`;
  };

  const submitStep = async () => {
    rootState.api.submitMovingDate({
      date: toDateString(surveyStore.movingDate),
      uuid: surveyStore.surveyId,
    });
  };

  return (
    <>
      <HeadingTwo>Move Date</HeadingTwo>
      <div className="date-picker-wrapper">
        <ReactDatePicker
          inline
          onChange={(date) => surveyStore.setMovingDate(date)}
          value={surveyStore.movingDate}
          open={true}
          selected={surveyStore.movingDate}
          filterDate={inTheFuture}
        />
        <ButtonContainer style={{ width: "66%" }}>
          <NextButton
            disabled={!surveyStore.movingDate}
            btnText="Done"
            handleSubmit={submitStep}
          />
        </ButtonContainer>
      </div>
    </>
  );
}

export default observer(MovingDate);
