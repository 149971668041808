import React, { useEffect, useReducer, useState } from "react";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { observer } from "mobx-react";

import {
  DividingContainer,
  FieldSet,
  HelpText,
  Input,
  NavContainer,
  Prompt,
} from "./UI";
import PanelButtonGroup from "./UI/PanelButtonGroup";
import PanelButton from "./UI/PanelButton";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { useRootStore } from "../../Stores/RootStore";
import { NextButton } from "../UI";
import { AccessSelector, HowManyRooms } from "./";
import { useModules } from "../../Hooks/useModules";
import { useNavigation } from "../../Providers/NavigationProvider";

function OriginAccess() {
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const { navigate } = useNavigation();

  const [howManyRoomsPrompt, setHowManyRoomsPrompt] = useState(false);
  useModules();

  const [currentPrompt, setCurrentPrompt] = useReducer(
    currentPromptReducer,
    null
  );

  const [nextDisabled, setNextDisabled] = useState(true);

  const validInput = (data) => {
    if (!data) return false;
    return data.trim() !== "";
  };

  useEffect(() => {
    if (
      validInput(surveyStore.originIndoor) &&
      validInput(surveyStore.originOutdoor) &&
      validInput(surveyStore.originParking)
    ) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  }, [
    nextDisabled,
    surveyStore.originIndoor,
    surveyStore.originOutdoor,
    surveyStore.originParking,
  ]);

  const closePrompt = () => {
    // slice and dice currentPrompt to get surveyStore access value
    let field = currentPrompt ? currentPrompt.split("-")[0] : "";
    field = field.charAt(0).toUpperCase() + field.slice(1);

    if (field && surveyStore[`origin${field}`] === "other") {
      surveyStore[`setOrigin${field}`]("");
      setCurrentPrompt(field.toLowerCase() + "-other");
    } else if (field && surveyStore[`origin${field}`] === "stairs") {
      //   surveyStore[`setOrigin${field}`]("");
      setCurrentPrompt(field.toLowerCase() + "-stairs");
    } else if (field && surveyStore[`origin${field}`] === "elevator") {
      setCurrentPrompt(field.toLowerCase() + "-elevator");
    } else {
      setCurrentPrompt(null);
    }
  };

  const setOriginAccess = (e) => {
    const field = e.target.name;
    const action = `setOrigin${field}`;
    const value = e.target.value;
    if (surveyStore[action]) {
      surveyStore[action](value);
    }
  };

  // TODO: this data should come from the server
  const formOptions = {
    parking: [
      { label: "Street", name: "parking", value: "street" },
      { label: "Driveway", name: "parking", value: "driveway" },
      { label: "Car Park", name: "parking", value: "car-park" },
      {
        label: "Parking Garage",
        name: "parking",
        value: "parking-garage",
      },
      { label: "Loading Zone", name: "parking", value: "loading-zone" },
      { label: "Loading Bay", name: "parking", value: "loading-bay" },
      { label: "Other", name: "parking", value: "other" },
    ],
    outdoor: [
      { label: "Short Walk", name: "outdoor", value: "short-walk" },
      { label: "Medium Walk", name: "outdoor", value: "medium-walk" },
      { label: "Long Walk", name: "outdoor", value: "long-walk" },
      { label: "Steep Hill", name: "outdoor", value: "steep-hill" },
      { label: "Other", name: "outdoor", value: "other" },
    ],
    indoor: [
      {
        label: "All one floor / Bungalo",
        name: "indoor",
        value: "all-one-floor",
      },
      {
        label: "Stairs",
        name: "indoor",
        value: "stairs",
        followUp: { question: "How many flights of stairs are there?" },
      },
      {
        label: "Dedicated Lift",
        name: "indoor",
        value: "dedicated-lift",
      },
      {
        label: "Elevator",
        name: "indoor",
        value: "elevator",
        followUp: {
          question: "Can it be reserved?",
          options: ["Can be reserved", "Shared"],
        },
      },
    ],
  };

  const submitStep = async () => {
    rootState.api
      .submitOriginAccess({
        uuid: surveyStore.surveyId,
        parking: surveyStore.originParking,
        "outdoor-access": surveyStore.originOutdoor,
        "indoor-access": surveyStore.originIndoor,
        stairs: surveyStore.originStairs,
        "indoor-elevator": surveyStore.originElevator,
      })
      .then((data) => {
        rootState.setModules(data.modules);
        if (surveyStore.howManyRooms === null) setHowManyRoomsPrompt(true);
        else {
          navigate(data.modules.next);
        }
      })
      .catch((e) => {
        rootState.setError({ message: e.message });
      });
  };

  const submitHowManyRooms = async () => {
    rootState.api
      .submitRoomCount({
        uuid: surveyStore.surveyId,
        room_count: surveyStore.howManyRooms,
      })
      .then(() => {
        setHowManyRoomsPrompt(false);
        navigate(rootState.modules.next);
      })
      .catch((e) => {
        rootState.setError({ message: e.message });
      });
  };

  return (
    <>
      <DividingContainer>
        {howManyRoomsPrompt && (
          <HowManyRooms
            roomCount={surveyStore.howManyRooms}
            handleNext={() => {
              submitHowManyRooms();
            }}
            setRoomCount={surveyStore.setHowManyRooms}
          />
        )}

        <FieldSet title="Origin Access">
          <PanelButtonGroup>
            <PanelButton
              handleClick={() => setCurrentPrompt("parking")}
              headerText="Parking"
              bodyText="Parking information about location"
              complete={surveyStore.originParking}
              icon={surveyStore.originParking ? faSquareCheck : faSquare}
            />
            <PanelButton
              handleClick={() => setCurrentPrompt("outdoor-access")}
              headerText="Outdoor Access"
              bodyText="The walk from the removal van to your front door"
              complete={surveyStore.originOutdoor}
              icon={surveyStore.originOutdoor ? faSquareCheck : faSquare}
            />
            <PanelButton
              handleClick={() => setCurrentPrompt("indoor-access")}
              headerText="In-door Access"
              bodyText="Getting around inside your home"
              complete={surveyStore.originIndoor}
              icon={surveyStore.originIndoor ? faSquareCheck : faSquare}
            />
          </PanelButtonGroup>
        </FieldSet>

        <HelpText>
          Please select items in each category that are required for your move
        </HelpText>
        <NavContainer>
          <NextButton
            handleSubmit={submitStep}
            disabled={nextDisabled}
          ></NextButton>
        </NavContainer>
      </DividingContainer>

      <AccessSelectorSection
        currentPrompt={currentPrompt}
        setCurrentPrompt={setCurrentPrompt}
        closePrompt={closePrompt}
        setOriginAccess={setOriginAccess}
        formOptions={formOptions}
      />
    </>
  );
}

const AccessSelectorSection = observer(
  ({
    currentPrompt,
    setCurrentPrompt,
    closePrompt,
    setOriginAccess,
    formOptions,
  }) => {
    const surveyStore = useSurveyStore();
    const [parkingOther, setParkingOther] = useState("");
    const [outdoorOther, setOutdoorOther] = useState("");

    switch (currentPrompt) {
      case "parking":
        return (
          <AccessSelector
            formLabel="Parking"
            access={surveyStore.originParking}
            setAccess={setOriginAccess}
            closePrompt={closePrompt}
            formOptions={formOptions.parking}
          />
        );
      case "outdoor-access":
        return (
          <AccessSelector
            formLabel="Outdoor"
            access={surveyStore.originOutdoor}
            setAccess={setOriginAccess}
            closePrompt={closePrompt}
            formOptions={formOptions.outdoor}
          />
        );
      case "indoor-stairs":
        return (
          <Prompt
            singleBtn={true}
            leftBtnText="Done"
            closeBtnClick={() => setCurrentPrompt(null)}
            leftBtnClick={() => setCurrentPrompt(null)}
          >
            <>
              <p>How many flights of stairs to reach your floor?</p>
              <Input
                type="number"
                placeholder="Number of flights"
                value={surveyStore.originStairs}
                handleChange={(e) => {
                  surveyStore.setOriginStairs(e.target.value);
                }}
              />
            </>
          </Prompt>
        );
      case "indoor-access":
        return (
          <AccessSelector
            formLabel="Indoor"
            access={surveyStore.originIndoor}
            setAccess={setOriginAccess}
            closePrompt={closePrompt}
            formOptions={formOptions.indoor}
          />
        );
      case "indoor-elevator":
        return (
          <Prompt
            leftBtnText="Shared"
            rightBtnText="Can be reserved"
            closeBtnClick={() => setCurrentPrompt(null)}
            rightBtnClick={() => {
              setCurrentPrompt(null);
              surveyStore.setOriginElevator("can-be-reserved");
            }}
            leftBtnClick={() => {
              setCurrentPrompt(null);
              surveyStore.setOriginElevator("shared");
            }}
          >
            <>
              <p>Can the elevator be reserved or is it shared?</p>
            </>
          </Prompt>
        );
      case "parking-other":
        return (
          <Prompt
            singleBtn
            leftBtnText="Done"
            closeBtnClick={closePrompt}
            leftBtnClick={() => {
              surveyStore.setOriginParking(parkingOther);
              closePrompt();
            }}
            leftBtnDisabled={parkingOther.trim().length === 0}
          >
            <>
              <p>Please Describe the parking situation.</p>
              <Input
                value={parkingOther}
                handleChange={(e) => setParkingOther(e.target.value)}
              />
            </>
          </Prompt>
        );
      case "outdoor-other":
        return (
          <Prompt
            singleBtn
            leftBtnText="Done"
            closeBtnClick={closePrompt}
            leftBtnClick={() => {
              surveyStore.setOriginOutdoor(outdoorOther);
              closePrompt();
            }}
            leftBtnDisabled={outdoorOther.trim().length === 0}
          >
            <>
              <p>Please Describe the outdoor access situation.</p>

              <Input
                value={outdoorOther}
                handleChange={(e) => setOutdoorOther(e.target.value)}
              />
            </>
          </Prompt>
        );
      default:
        return null;
    }
  }
);

export default observer(OriginAccess);

function currentPromptReducer(currentPrompt, prompt) {
  switch (prompt) {
    case "parking":
    case "parking-other":
    case "indoor-access":
    case "indoor-stairs":
    case "indoor-elevator":
    case "outdoor-access":
    case "outdoor-other":
      return prompt;
    default:
      return null;
  }
}
