import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Switch, Route, useHistory } from "react-router-dom";

import { NavigationProvider } from "../../Providers/NavigationProvider";
import { useRootStore } from "../../Stores/RootStore";
import {
  Welcome,
  Contact,
  OriginLocation,
  OriginAccess,
  Rooms,
  SpecialItems,
  DestinationLocation,
  DestinationAccess,
  SurveyComplete,
} from ".";
import { Video } from "../Video";
import { FixedHeightContainer, ResponsiveContainer } from "./UI";

const OverflowContainer = styled.div`
  max-height: 100vh;
  overflow-y: hidden;
  height: 100%;
`;

function FormWrapper() {
  const { rootState } = useRootStore();

  const switchRef = useRef(null);

  const moduleMap = {
    "/": Welcome,
    "/contact": Contact,
    "/origin-location": OriginLocation,
    "/origin-access": OriginAccess,
    "/rooms": Rooms,
    "/video": Video,
    "/special-items": SpecialItems,
    "/destination-location": DestinationLocation,
    "/destination-access": DestinationAccess,
    "/survey-complete": SurveyComplete,
  };

  const renderRoutes = () => {
    if (!rootState.modules || !rootState.modules.available_modules)
      return (
        <Route exact path="/">
          <Welcome />
        </Route>
      );

    return rootState.modules.available_modules.map((m) => {
      const Module = moduleMap[m];
      return (
        <Route key={m} exact={m === "/" ? true : false} path={m}>
          <Module />
        </Route>
      );
    });
  };

  return (
    <FixedHeightContainer>
      <Switch ref={switchRef}>
        <NavigationProvider>
          {rootState.inVideoFlow ? (
            <OverflowContainer>
              <Video />
            </OverflowContainer>
          ) : (
            <ResponsiveContainer>
              {rootState.initialized && (
                <Route path="/*">
                  <NoMatch />
                </Route>
              )}
              {renderRoutes()}
            </ResponsiveContainer>
          )}
        </NavigationProvider>
      </Switch>
    </FixedHeightContainer>
  );
}

function NoMatch() {
  const history = useHistory();
  const { rootState } = useRootStore();

  const allModules = [
    "/",
    "/contact",
    "/origin-location",
    "/origin-access",
    "/rooms",
    "/video",
    "/special-items",
    "/destination-location",
    "/destination-access",
    "/survey-complete",
  ];

  const nav = (path = "/") => {
    let qParams = new URLSearchParams(window.location.search);
    history.replace(`${path}?${qParams.toString()}`);
  };

  useEffect(() => {
    if (!allModules.includes(window.location.pathname)) {
      nav();
    }
  }, []);

  useEffect(() => {
    if (rootState.modules && rootState.modules.available_modules) {
      if (
        !rootState.modules.available_modules.includes(window.location.pathname)
      ) {
        nav(rootState.modules.previous);
      }
    }
  }, [rootState.modules]);

  return null;
}

export default observer(FormWrapper);
