// FooterButtons are not used anymore
import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { useRootStore } from "../../Stores/RootStore";

const styles /** // TODO: replace with styled-components */ = {
  button: {
    fontSize: "32px",
    color: "#a02200",
    backgroundColor: "white",
    border: "none",
    borderRadius: "8px",
    maxWidth: "40px",
    width: "40px",
    height: "40px",
    padding: "0",
  },
};

function FooterButton({ isBackButton }) {
  const { rootState } = useRootStore();

  // TODO: add validation when clicking Next, required form fields should be filled
  const nextPage = () => {
    rootState.setCurrentStep(rootState.currentStep + 1);
  };
  const prevPage = () => {
    rootState.setCurrentStep(rootState.currentStep - 1);
  };

  const Button = ({ handleClick, children }) => {
    return (
      <button style={styles.button} onClick={handleClick}>
        {children}
      </button>
    );
  };

  if (isBackButton) {
    return (
      <Button handleClick={prevPage}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
    );
  } else {
    return (
      <Button handleClick={nextPage}>
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
    );
  }
}

export default observer(FooterButton);
