import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import { useNavigation } from "../../../Providers/NavigationProvider";
import { useRootStore } from "../../../Stores/RootStore";
import { BackButton } from ".";

const Legend = styled.legend`
  position: relative;
  left: -12px;
  width: 100%;
  text-align: center;
  padding: 16px 0px;
  font-size: 18px;
  color: #444;
  font-family: montserrat;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledFieldSet = styled.fieldset`
  width: 100%;
  border: none;
  margin: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 100%;
  justify-content: center;
`;

// wraps form-like content with a form label and back button, displays vertically and evenly
function FieldSet({ title, children }) {
  const { rootState } = useRootStore();
  const { navigate } = useNavigation();

  return (
    <StyledFieldSet>
      <Legend>
        <BackButton
          onClick={() => {
            navigate(rootState.modules.previous);
          }}
        />
        {title}
      </Legend>
      <FormContent>{children}</FormContent>
    </StyledFieldSet>
  );
}

export default observer(FieldSet);
