import React from "react";
import { Radio } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Icon = styled.span`
    font-size: 24px;
    color: ${(props) => (props.checked ? "#fff" : "#ddd")};
`;

function RadioBtn(props) {
    return (
        <Radio
            checkedIcon={
                <Icon checked>
                    <FontAwesomeIcon icon={faCircleCheck} />
                </Icon>
            }
            icon={
                <Icon>
                    <FontAwesomeIcon icon={faCircle} />
                </Icon>
            }
            {...props}
        />
    );
}

export default RadioBtn;
