import React from "react";
import { RadioGroup } from "@mui/material";

import { BottomCard, Label, RadioBtn } from "./UI";

function AccessSelector({
  closePrompt,
  setAccess,
  access,
  formOptions,
  formLabel,
}) {
  return (
    <BottomCard title={formLabel} closePrompt={closePrompt}>
      <RadioGroup name={formLabel} onChange={setAccess} value={access}>
        {formOptions.map(({ label, value }, idx) => (
          <Label
            sx={{
              backgroundColor: access === value ? "#1976d2" : "white",
              color: access === value ? "#fff" : "#000",
            }}
            key={value}
            value={value}
            label={label}
            control={<RadioBtn autoFocus={idx === 0} />}
          />
        ))}
      </RadioGroup>
    </BottomCard>
  );
}

export default AccessSelector;
