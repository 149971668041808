import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";

import { SurveyStore } from "./SurveyStore";
import { Api } from "../API";

export class RootStore {
  constructor() {
    this.initialized = false;
    this.steps = [];
    this.currentStep = 0;
    this.inVideoFlow = false;
    this.complete = false;
    this.surveyStore = new SurveyStore(this);
    this.recorder = null;
    this.recData = null;
    this.modules = {};
    this.currentModule = null;

    this.deviceSupportError = null;

    this.serverUrl = process.env.REACT_APP_API_URL;

    this.api = new Api(this.serverUrl);

    this.error = null;

    this.gyroEnabled = false;

    this.debugMode = false;

    if (new URLSearchParams(window.location.search).has("debug")) {
      this.debugMode = true;
      console.log("operating app in debug mode");
    }

    makeAutoObservable(this);
  }

  setInitialized = () => {
    this.initialized = true;
  };

  setModules = (modules) => {
    this.modules = modules;
  };

  setDeviceSupportError = () => {
    this.deviceSupportError = true;
  };

  promptForGyro = async () => {
    if (window.DeviceMotionEvent) {
      try {
        // ios devices require you to requestPermission first
        const response = await window.DeviceMotionEvent.requestPermission();
        this.setGyroEnabled(response === "granted");
      } catch (e) {
        console.log(e);
        // if deviceMotionEvent exists, but no requestPermission fn, gyro is enabled
        this.setGyroEnabled(true);
      }
    } else {
      // gyro is not supported
      console.log("motion detection is not supported");
    }
  };

  setGyroEnabled = (enabled) => {
    this.gyroEnabled = enabled;
  };

  setSteps = (steps) => {
    this.steps = [...steps];
  };

  setError = (error) => {
    this.error = error;
  };

  setCurrentStep = (stepIndex) => {
    if (stepIndex <= 0) {
      this.currentStep = 0;
    } else if (stepIndex > this.steps.length - 1) {
      this.currentStep = this.steps.length - 1;
      this.setComplete();
    } else {
      this.currentStep = stepIndex;
    }
  };

  setInVideoFlow = (inVideoFlow) => {
    this.inVideoFlow = inVideoFlow ?? false;
  };

  setComplete = () => {
    this.complete = true;
  };

  setRecorder = (rec) => {
    this.recorder = rec;
  };

  setRecData = (data) => {
    this.recData = data;
  };
}

export const createRootStore = () => new RootStore();

export const rootStoreContext = createContext(null);

export const useRootStore = () => {
  const store = useContext(rootStoreContext);
  if (!store) {
    throw Error("must use useRootState inside RootStateProvider");
  }

  return store;
};
