import React from "react";

import { useSurveyStore } from "../../Stores/SurveyStore";

function RecordVideo() {
  const surveyState = useSurveyStore();

  return (
    <div>
      <button
        onClick={() => {
          surveyState.setIsRecording(false);
        }}
      >
        close
      </button>
    </div>
  );
}

export default RecordVideo;
