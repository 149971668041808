import styled from "styled-components";

const NavContainer = styled.nav`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export default NavContainer;
