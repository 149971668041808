import { FormControlLabel } from "@mui/material";
import styled from "styled-components";

const Label = styled(FormControlLabel)`
  border-radius: 4px;
  margin: 0;
  margin-bottom: 16px;
  width: 100%;
  transition: all 0.3s ease;

  // '& > span' used to reach into Material UI to apply custom font
  & > span {
    font-family: montserrat;
  }
`;

export default Label;
