import React from 'react'
import styled from 'styled-components'

import { WelcomeContainer, HeadingOne, WelcomeText } from "./Form/UI";
import { Header } from "./UI";

const WarningHeader = styled(HeadingOne)`
  text-align: center;
`;

function DeviceUnsupported() {

    return (
      <WelcomeContainer>
        <Header />
        <WarningHeader>Phone version not supported</WarningHeader>
        <WelcomeText>
          Please update your phones operating system, and browser to their
          latest versions in order to use this app.
          <br />
          {window.navigator.userAgent.includes("iPhone") &&
            "Settings > General > Software Update"}
        </WelcomeText>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
            marginBottom: "32px",
          }}
        ></div>
      </WelcomeContainer>
    );
}

export default DeviceUnsupported;
