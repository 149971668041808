import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import styled from "styled-components";

import { FormWrapper } from "./Form";
import { useSurveyStore } from "../Stores/SurveyStore";
import { ErrorCard } from "./UI";
import { useRootStore } from "../Stores/RootStore";
import DeviceUnsupported from "./DeviceUnsupported";

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function App() {
  const [serverUnhealthy, setHealth] = useState(null);
  const surveyStore = useSurveyStore();
  const { rootState } = useRootStore();
  const [loading, setLoading] = useState(true);

  // break app if no survey id
  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.get("id")) {
      setHealth(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    rootState.api.healthCheck().then((healthy) => {
      if (healthy) {
        rootState.api
          .initialize()
          .then((data) => {
            const surveyId = data.uuid;
            surveyStore.setSurveyId(surveyId);

            if (data.modules) {
              rootState.setModules(data.modules);
            }

            if (data.inventory_list_id) {
              surveyStore.setInventoryListId(data.inventory_list_id);
            }

            if (data.email) {
              surveyStore.setEmail(data.email);
            }
            if (data.name) {
              surveyStore.setFullName(data.name);
            }
            if (data.phone) {
              surveyStore.setPhone(data.phone.slice(-10));
            }
            const { move_date } = data;
            if (move_date) {
              const [year, monthIndex, day] = move_date.split("-");
              const date = new Date();
              date.setFullYear(year);
              date.setMonth(monthIndex - 1);
              date.setDate(day);
              surveyStore.setMovingDate(date);
            }
            const { address_locations } = data;
            const { origin, destination } = address_locations;

            if (origin.address) {
              surveyStore.setOriginStreetAddr(
                origin.address?.street_address || ""
              );
              surveyStore.setOriginHouseNumber(origin.address?.house || "");
              surveyStore.setOriginGateCode(origin.address?.gate_lock || "");
              surveyStore.setOriginCity(origin.address?.city || "");
              surveyStore.setOriginState(origin.address?.state || "");
              surveyStore.setOriginCountry(origin.address?.country || "");
              surveyStore.setOriginPostalCode(
                origin.address?.postal_code || ""
              );
            }
            if (origin.access) {
              surveyStore.setOriginParking(origin.access.parking);
              surveyStore.setOriginIndoor(origin.access["indoor-access"]);
              surveyStore.setOriginOutdoor(origin.access["outdoor-access"]);
            }
            if (destination.address) {
              surveyStore.setDestinationStreetAddr(
                destination.address?.street_address || ""
              );
              surveyStore.setDestinationHouseNumber(
                destination.address?.house || ""
              );
              surveyStore.setDestinationGateCode(
                destination.address?.gate_lock || ""
              );
              surveyStore.setDestinationCity(destination.address?.city || "");
              surveyStore.setDestinationState(destination.address?.state || "");
              surveyStore.setDestinationCountry(
                destination.address?.country || ""
              );
              surveyStore.setDestinationPostalCode(
                destination.address?.postal_code || ""
              );
            }
            if (destination.access) {
              surveyStore.setDestinationParking(destination.access.parking);
              surveyStore.setDestinationIndoor(
                destination.access["indoor-access"]
              );
              surveyStore.setDestinationOutdoor(
                destination.access["outdoor-access"]
              );
            }

            const { inventory_notes_json } = data;
            if (inventory_notes_json) {
              if (inventory_notes_json.room_count) {
                surveyStore.setHowManyRooms(inventory_notes_json.room_count);
              }
              const { detected_labels } = inventory_notes_json;
              if (detected_labels) {
                const { rooms } = detected_labels;
                const uploadedRooms = Object.keys(rooms).map((i) =>
                  parseInt(i)
                );
                //const roomIds = JSON.parse(JSON.stringify(surveyStore.roomList)).map(({id}) => id)
                const surveyStoreRooms = surveyStore.roomList.map((r) => r.id);

                // grab any custom rooms and merge with room list
                uploadedRooms.forEach((id) => {
                  if (!surveyStoreRooms.includes(id)) {
                    surveyStore.roomList.push({
                      icon: null,
                      id: id,
                      isRoom: true,
                      item: rooms[id].room_name,
                      complete: true,
                    });
                  }
                });

                surveyStore.setRoomList(
                  surveyStore.roomList.map((room) => {
                    if (uploadedRooms.includes(room.id)) {
                      return { ...room, complete: true };
                    } else {
                      return room;
                    }
                  })
                );
              }
            }
            rootState.api
              .loadSpecialItems()
              .then((data) => {
                surveyStore.setSpecialItems(data);
              })
              .catch((e) => {
                console.log(e);
                rootState.setError({
                  message: "We are unable to process surveys at this time.",
                });
              })
              .finally(() => {
                setLoading(false);
                rootState.setInitialized();
                rootState.api
                  .getInventoryList(surveyStore.inventoryListId)
                  .then((data) => {
                    surveyStore.setInventoryList(data);
                  });
              });
          })
          .catch((e) => {
            console.log(e);
            rootState.setError({
              message: "We are unable to process surveys at this time.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setHealth(true);
        setLoading(false);
      }
    });

    return () => {
      setLoading(false);
    };
  }, []);

  if (typeof window.MediaRecorder !== "function") {
    rootState.setDeviceSupportError();
  }

  if (rootState.deviceSupportError) {
    return <DeviceUnsupported />;
  }
  return (
    <Container>
      <ErrorCard />
      {serverUnhealthy ? (
        <p>
          Were currently doing some maintenance on the application. Check back
          later!
        </p>
      ) : (
        <>
          {loading && <Loading />}
          <FormWrapper />
        </>
      )}
    </Container>
  );
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const Spinner = styled.div`
  width: 120px;
  height: 120px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #a02200;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Loading() {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  );
}

export default observer(App);
