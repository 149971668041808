import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./Components/App";
import { createRootStore } from "./Stores/RootStore";
import { RootStateProvider } from "./Providers/RootStateProvider";
import { SurveyStateProvider } from "./Providers/SurveyStateProvider";
import { createSurveyStore } from "./Stores/SurveyStore";

const rootStore = createRootStore(); // pass in data from server here
const surveyStore = createSurveyStore(rootStore);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <StyledEngineProvider injectFirst>
        <RootStateProvider rootState={rootStore}>
          <SurveyStateProvider surveyState={surveyStore}>
            <CssBaseline />
            <App />
          </SurveyStateProvider>
        </RootStateProvider>
      </StyledEngineProvider>
    </Router>
  </React.StrictMode>
);
