import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Message = styled.p`
  position: fixed;
  top: ${({ show }) => (show ? "50px" : "-50px")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  z-index: 20;
  transition: all 0.5s ease;
  text-align: center;
  background-color: #ffffff50;
  padding: 1rem 2rem;
  border-radius: 1rem;
`;

export function SlowDown({ slowDown }) {
  return (
    <Container>
    <Message show={slowDown}>Slow Down</Message>
    </Container>
  );
}

