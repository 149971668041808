import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";

export class SurveyStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rooms = [];
    this.currentRoom = null;
    this.firstName = "";
    this.surname = "";
    this.email = "";
    this.phone = "";
    this.movingDate = "";
    this.originStreetAddr = "";
    this.originHouseNumber = "";
    this.originGateCode = "";
    this.originCity = "";
    this.originState = "";
    this.customOriginState = "";
    this.originCountry = "";
    this.originPostalCode = "";
    this.destinationStreetAddr = "";
    this.destinationHouseNumber = "";
    this.destinationGateCode = "";
    this.destinationCity = "";
    this.destinationState = "";
    this.destinationCountry = "";
    this.destinationPostalCode = "";
    this.originParking = "";
    this.originOutdoor = "";
    this.originIndoor = "";
    this.originStairs = "";
    this.originElevator = "";
    this.destinationElevator = "";
    this.destinationStairs = "";
    this.destinationParking = "";
    this.destinationOutdoor = "";
    this.destinationIndoor = "";
    this.extraStopStreetAddr = "";
    this.extraStopHouseNumber = "";
    this.extraStopGateCode = "";
    this.extraStopCity = "";
    this.extraStopCountry = "";
    this.extraStopPostalCode = "";
    this.roomOpen = false;
    this.surveyId = "";
    this.fullName = "";
    this.specialItems = null;
    this.roomList = [];
    this.inventoryListId = null;
    this.inventoryList = [];
    this.howManyRooms = null;

    makeAutoObservable(this);

    try {
      this.fetchRoomList();
    } catch (e) {
      console.error(e);
    }
  }

  setHowManyRooms = (value) => {
    this.howManyRooms = value;
  };

  setInventoryListId(id) {
    this.inventoryListId = id;
  }

  roomListReducer(action) {
    // TODO: move room list state here
    switch (action.type) {
    }
  }

  setOriginElevator = (value) => {
    this.originElevator = value;
  };

  setDestinationElevator = (value) => {
    this.destinationElevator = value;
  };

  fetchInventoryList = async () => {
    if (this.rootStore.serverUrl) {
      const response = await fetch(
        this.rootStore.serverUrl +
          "/presets/inventory-items/" +
          this.inventoryListId ?? "",
        {
          headers: {
            "x-app-version": "1.0",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const { data } = await response.json();
        this.setInventoryList(data);
      }
    }
  };

  setInventoryList = (list) => {
    this.inventoryList = list;
  };

  fetchRoomList = async () => {
    if (this.rootStore.serverUrl) {
      const response = await fetch(
        `${this.rootStore.serverUrl}/presets/rooms`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-app-version": "1.0",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.message === "success") {
          this.setRoomList(data.data.map((r) => ({ ...r, isRoom: true })));
        } else {
          throw Error("failed to fetch rooms");
        }
      }
    }
  };

  setRoomList = (list) => {
    this.roomList = list;
  };

  setSurveyId = (id) => {
    this.surveyId = id;
  };

  setFullName = (name) => {
    this.fullName = name;
  };

  setFirstName = (name) => {
    this.firstName = name;
  };

  setSurname = (name) => {
    this.surname = name;
  };

  setEmail = (email) => {
    this.email = email;
  };

  setPhone = (phone) => {
    this.phone = phone;
  };

  setRooms = (rooms) => {
    this.rooms = rooms;
  };

  setMovingDate = (date) => {
    this.movingDate = date;
  };

  setOriginStreetAddr = (addr) => {
    this.originStreetAddr = addr;
  };

  setOriginHouseNumber = (number) => {
    this.originHouseNumber = number;
  };

  setOriginGateCode = (code) => {
    this.originGateCode = code;
  };

  setOriginCity = (city) => {
    this.originCity = city;
  };

  setOriginCountry = (country) => {
    this.originCountry = country;
  };

  setOriginPostalCode = (code) => {
    this.originPostalCode = code;
  };

  setOriginState = (state) => {
    this.originState = state;
  };

  setCustomOriginState = (state) => {
    this.customOriginState = state;
  };

  setDestinationStairs = (value) => {
    this.destinationStairs = value;
  };
  setDestinationStreetAddr = (addr) => {
    this.destinationStreetAddr = addr;
  };

  setDestinationHouseNumber = (number) => {
    this.destinationHouseNumber = number;
  };

  setDestinationGateCode = (code) => {
    this.destinationGateCode = code;
  };

  setDestinationCity = (city) => {
    this.destinationCity = city;
  };

  setDestinationState = (state) => {
    this.destinationState = state;
  };

  setDestinationCountry = (country) => {
    this.destinationCountry = country;
  };

  setDestinationPostalCode = (code) => {
    this.destinationPostalCode = code;
  };

  setCurrentRoom = (roomName) => {
    [this.currentRoom] = this.rooms.filter(
      (room) => room.roomName === roomName
    );
  };

  setSpecialItems = (items) => {
    this.specialItems = items;
  };

  setSpecialItem = (item) => {
    this.specialItems[item.item] = item;
  };

  setOriginParking = (value) => {
    this.originParking = value;
  };

  setOriginStairs = (value) => {
    this.originStairs = value;
  };

  setOriginOutdoor = (value) => {
    this.originOutdoor = value;
  };

  setOriginIndoor = (value) => {
    this.originIndoor = value;
  };

  setDestinationParking = (value) => {
    this.destinationParking = value;
  };

  setDestinationOutdoor = (value) => {
    this.destinationOutdoor = value;
  };

  setDestinationIndoor = (value) => {
    this.destinationIndoor = value;
  };

  setRoomOpen = (open) => {
    this.roomOpen = !!open;
  };

  setExtraStopHouseNumber = (value) => {
    this.extraStopHouseNumber = value;
  };

  setExtraStopStreetAddr = (value) => {
    this.extraStopStreetAddr = value;
  };

  setExtraStopGateCode = (value) => {
    this.extraStopGateCode = value;
  };

  setExtraStopCountry = (value) => {
    this.extraStopCountry = value;
  };

  setExtraStopCity = (value) => {
    this.extraStopCity = value;
  };

  createRoom = (roomName) => {
    let [room] = this.roomList.filter((r) => roomName === r.item);

    const newRoom = {
      roomName: room ? room.item : roomName,
      roomId: room ? room.id : null,
      videoStream: "",
      streamSrc: "",
      notes: "",
      notMoving: "",
      cratingDimensions: "",
      recordedBlob: null,
      complete: false,
    };

    const roomNames = this.rooms.map(({ roomName }) => roomName);
    if (!roomNames.includes(roomName)) {
      this.setRooms([...this.rooms, newRoom]);
    }
    this.setCurrentRoom(roomName);
  };

  getRoom = (roomName) => {
    return this.rooms.filter((room) => room.roomName === roomName);
  };

  updateRooms = (roomData) => {
    const currentRoom = this.currentRoom;
    const otherRooms = this.rooms.filter(
      ({ roomName }) => currentRoom.roomName !== roomName
    );

    // set rooms prop to maintain streamSrc data
    this.setRooms([...otherRooms, { ...currentRoom, ...roomData }]);

    // get ids of rooms that have been recorded and submitted and merge with roomList from server
    const completedRooms = this.rooms
      .filter((r) => r.complete)
      .map((r) => r.roomId);
    const updatedRooms = this.roomList.map((r) => {
      if (completedRooms.includes(r.id)) {
        return { ...r, complete: true };
      } else {
        return r;
      }
    });
    this.setRoomList(updatedRooms);
  };
}

export const createSurveyStore = (rootStore) => new SurveyStore(rootStore);

export const surveyStoreContext = createContext(null);

export const useSurveyStore = () => {
  const store = useContext(surveyStoreContext);
  if (!store) {
    throw Error("must use useSurveyStore inside SurveyStateProvider");
  }
  return store.surveyState;
};
