import React from "react";
import Select from "react-select";
import styled from "styled-components";

import { DividingContainer, FieldSet, VerticalFlexContainer } from "../Form/UI";
import { SaveButton } from "../UI";

const FullWidth = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

function ItemsNotMoving({ options, onChange, onSubmit }) {
  return (
    <DividingContainer>
      <FieldSet title="Items Not Moving">
        <VerticalFlexContainer>
          <Main>
            <p>Please select any items that are not moving</p>
            <FullWidth>
              <Select
                onChange={onChange}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                options={options}
                isMulti
              />
            </FullWidth>
            <SaveButton onClick={onSubmit}>Save and Upload</SaveButton>
          </Main>
        </VerticalFlexContainer>
      </FieldSet>
    </DividingContainer>
  );
}

export default ItemsNotMoving;
