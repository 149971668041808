import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { UnSizedButton } from "../../UI";

const Content = styled.div`
  background-color: #ddd;
  border-radius: 16px 16px 0 0;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.header`
  font-size: 32px;
  text-align: center;
  font-family: montserrat;
  font-weight: bold;
`;

const BottomCardContainer = styled(Box)`
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 3;
  transition: all 0.2s ease;
  bottom: ${({ expanded }) => (expanded ? "0" : "-25%")};
  opacity: ${({ expanded }) => (expanded ? "1" : "0")};
  width: 100%;
`;

const BlurredBg = styled.div`
  ${({ blurred }) => (blurred ? "--blurriness: 3px" : "--blurriness: 0px")};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  backdrop-filter: blur(var(--blurriness));
  webkitbackdropfilter: blur(var(--blurriness));
`;

function BottomCard({ title, children, closePrompt }) {
  const [expanded, setExpanded] = useState(false);
  const timeoutRef = useRef();
  useEffect(() => {
    setExpanded(true);

    let ref = timeoutRef;

    return () => {
      clearTimeout(ref.current);
    };
  }, []);

  return (
    <>
      <BlurredBg blurred={expanded ? true : undefined} />
      <BottomCardContainer expanded={expanded ? true : undefined}>
        <Header>{title}</Header>
        <Content>
          {children}

          <UnSizedButton
            onClick={() => {
              setExpanded(false);
              timeoutRef.current = setTimeout(closePrompt, 300);
            }}
          >
            Done
          </UnSizedButton>
        </Content>
      </BottomCardContainer>
    </>
  );
}

export default BottomCard;
