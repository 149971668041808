import styled from "styled-components";

const ResponsiveContainer = styled.div`
  height: 100%;
  @media (min-width: 550px) {
    max-width: 70%;
    margin: 0 auto;
  }
`;
export default ResponsiveContainer;
