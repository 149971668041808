import React, { useEffect } from "react";

import { UploadRecordPrompt } from "../Video";
import { useSurveyStore } from "../../Stores/SurveyStore";
import { VerticalFlexContainer } from "./UI";

function RoomName({ room }) {
  const surveyState = useSurveyStore();

  // sets and creates the current room if not exists
  useEffect(() => {
    if (surveyState.rooms.every((r) => r.roomName !== room)) {
      surveyState.createRoom(room);
    } else {
      surveyState.setCurrentRoom(room);
    }
  }, [surveyState, room]);

  return (
    <VerticalFlexContainer>
      <UploadRecordPrompt />
    </VerticalFlexContainer>
  );
}

export default RoomName;
