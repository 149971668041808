import React, { useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledPrompt = styled(Box)`
  width: 80%;
  padding: 32px;
  position: fixed;
  left: 10%;
  top: 20vh;
  //   height: 40%;
  z-index: 300;
  border-radius: 14px;
  background-color: #fcfcfc;
  box-shadow: 0 10px 20px 2px #aaa;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const BackgroundBlur = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  backdropfilter: blur(0px);
  webkitbackdropfilter: blur(0px);
  transition: all 0.3s ease;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ singleBtn }) =>
    singleBtn ? "center" : "space-between"};
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  width: min-content;
  min-width: unset;
  :hover {
    background-color: transparent;
  }
`;

const PromptContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  background-color: #a02200;
  color: #fff;
  width: ${({ singleBtn }) => (singleBtn ? "75%" : "40%")};
  padding: 8px;
  :hover {
    background-color: #a02200;
  }
  :disabled {
    background-color: #9e9e9e;
    color: #fff;
  }
`;

function Prompt({
  children,
  leftBtnText = "Yes",
  rightBtnText = "No",
  closeBtnClick,
  leftBtnClick,
  rightBtnClick,
  singleBtn,
  noButtons,
  leftBtnDisabled = false,
  customStyles = {},
  hideCloseBtn = false,
}) {
  if (singleBtn && noButtons) {
    throw Error(
      "cannot have `singleBtn` and `noButtons` set to true at the same time"
    );
  }

  const overlayRef = useRef();
  const promptRef = useRef();

  useEffect(() => {
    let t;
    if (overlayRef.current) {
      setTimeout(() => {
        if (overlayRef.current) {
          overlayRef.current.style.backdropFilter = "blur(3px)";
          overlayRef.current.style.webkitBackdropFilter = "blur(3px)";
        }
      }, 0);
    }

    // focus prompt on render
    if (promptRef.current) {
      promptRef.current.tabIndex = -1;
      promptRef.current.focus();
    }

    document.body.style.overflowY = "hidden";
    return () => {
      clearTimeout(t);
      document.body.style.overflowY = "initial";
    };
  }, []);

  return (
    <>
      <StyledPrompt ref={promptRef} sx={customStyles}>
        {!hideCloseBtn && (
          <CloseButton onClick={closeBtnClick}>
            <FontAwesomeIcon color="#777" icon={faX} />
          </CloseButton>
        )}
        <PromptContent>{children}</PromptContent>
        <ButtonGroup singleBtn={singleBtn && true}>
          {noButtons ? (
            <></>
          ) : (
            <>
              <StyledButton
                singleBtn={singleBtn && true}
                onClick={leftBtnClick}
                disabled={leftBtnDisabled}
              >
                {leftBtnText}
              </StyledButton>
              {!singleBtn && (
                <StyledButton onClick={rightBtnClick}>
                  {rightBtnText}
                </StyledButton>
              )}
            </>
          )}
        </ButtonGroup>
      </StyledPrompt>
      <BackgroundBlur
        ref={overlayRef}
        onClick={closeBtnClick} // click outside of prompt to dismiss
      ></BackgroundBlur>
    </>
  );
}

export default Prompt;
