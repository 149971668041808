import React from "react";
import styled from "styled-components";

export const Time = styled.div`
  color: #a02200;
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  position: fixed;
  top: 24px;
  text-align: center;
`;
