import { useState, useEffect } from "react";

export function useTimer() {
  const [seconds, setSeconds] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let i;

    if (active) {
      i = setInterval(() => {
        setSeconds(seconds + 1);
      }, 1000);
    } else {
      setSeconds(0);
    }

    return () => {
      clearInterval(i);
    };
  }, [seconds, active]);

  const startTimer = () => {
    setSeconds(0);
    setActive(true);
  };
  const clearTimer = () => {
    setSeconds(0);
    setActive(false);
  };
  return [seconds, startTimer, clearTimer];
}
