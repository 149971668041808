import { rootStoreContext } from "../Stores/RootStore";

export const RootStateProvider = ({ rootState, children }) => {
  const stores = {
    rootState,
  };
  return (
    <rootStoreContext.Provider value={stores}>
      {children}
    </rootStoreContext.Provider>
  );
};
