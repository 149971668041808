import styled from "styled-components";

const Instructions = styled.p`
  font-family: inter;
  border: solid 1px #dde;
  padding: 0.5rem;
  font-size: 13px;
  border-radius: 1rem;
  background-color: #fafafa;
  box-shadow: 0 2px 4px #ddd;
  margin-bottom: -1.8rem;
  margin-top: -0.8rem;
  text-align: center;
`;

export default Instructions;
