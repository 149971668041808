export const states = {
  Canada: [
    { name: "Alberta" },
    { name: "British Columbia" },
    { name: "Manitoba" },
    { name: "New Brunswick" },
    { name: "Newfoundland and Labrador" },
    { name: "Northwest Territories" },
    { name: "Nova Scotia" },
    { name: "Nunavut" },
    { name: "Ontario" },
    { name: "Prince Edward Island" },
    { name: "Quebec" },
    { name: "Saskatchewan" },
    { name: "Yukon" },
  ],
  Mexico: [
    { name: "Aguascalientes" },
    { name: "Baja California" },
    { name: "Baja California Sur" },
    { name: "Campeche" },
    { name: "Chiapas" },
    { name: "Chihuahua" },
    { name: "Coahuila" },
    { name: "Colima" },
    { name: "Durango" },
    { name: "Guanajuato" },
    { name: "Guerrero" },
    { name: "Hidalgo" },
    { name: "Jalisco" },
    { name: "México" },
    { name: "Mexico City" },
    { name: "Michoacán" },
    { name: "Morelos" },
    { name: "Nayarit" },
    { name: "Nuevo León" },
    { name: "Oaxaca" },
    { name: "Puebla" },
    { name: "Querétaro" },
    { name: "Quintana Roo" },
    { name: "San Luis Potosí" },
    { name: "Sinaloa" },
    { name: "Sonora" },
    { name: "Tabasco" },
    { name: "Tamaulipas" },
    { name: "Tlaxcala" },
    { name: "Veracruz" },
    { name: "Yucatán" },
    { name: "Zacatecas" },
  ],
  "United States": [
    { name: "Alabama" },
    { name: "Alaska" },
    { name: "Arizona" },
    { name: "Arkansas" },
    { name: "California" },
    { name: "Colorado" },
    { name: "Connecticut" },
    { name: "Delaware" },
    { name: "Florida" },
    { name: "Georgia" },
    { name: "Hawaii" },
    { name: "Idaho" },
    { name: "Illinois" },
    { name: "Indiana" },
    { name: "Iowa" },
    { name: "Kansas" },
    { name: "Kentucky" },
    { name: "Louisiana" },
    { name: "Maine" },
    { name: "Maryland" },
    { name: "Massachusetts" },
    { name: "Michigan" },
    { name: "Minnesota" },
    { name: "Mississippi" },
    { name: "Missouri" },
    { name: "Montana" },
    { name: "Nebraska" },
    { name: "Nevada" },
    { name: "New Hampshire" },
    { name: "New Jersey" },
    { name: "New Mexico" },
    { name: "New York" },
    { name: "North Carolina" },
    { name: "North Dakota" },
    { name: "Ohio" },
    { name: "Oklahoma" },
    { name: "Oregon" },
    { name: "Pennsylvania" },
    { name: "Rhode Island" },
    { name: "South Carolina" },
    { name: "South Dakota" },
    { name: "Tennessee" },
    { name: "Texas" },
    { name: "Utah" },
    { name: "Vermont" },
    { name: "Virginia" },
    { name: "Washington" },
    { name: "Washington DC" },
    { name: "West Virginia" },
    { name: "Wisconsin" },
    { name: "Wyoming" },
  ],
  "United Kingdom": [
    { name: "England" },
    { name: "Scotland" },
    { name: "Wales" },
    { name: "Northern Ireland" }
  ],
  "Austria": [
    { "name": "Burgenland" },
    { "name": "Carinthia" },
    { "name": "Lower Austria" },
    { "name": "Upper Austria" },
    { "name": "Salzburg" },
    { "name": "Styria" },
    { "name": "Tyrol" },
    { "name": "Vorarlberg" },
    { "name": "Vienna" }
  ],
  "Belgium": [
    { "name": "Bruxelles-Capitale, Region de (fr), Brussels Hoofdstedelijk Gewest (nl)" },
    { "name": "Vlaamse Gewest (nl)" },
    { "name": "Antwerpen (nl)" },
    { "name": "Limburg (nl)" },
    { "name": "Oost-Vlaanderen (nl)" },
    { "name": "Vlaams Brabant (nl)" },
    { "name": "West-Vlaanderen (nl)" },
    { "name": "Wallonne, Region (fr)" },
    { "name": "Brabant Wallon (fr)" },
    { "name": "Hainaut (fr)" },
    { "name": "Liège (fr)" },
    { "name": "Luxembourg (fr)" },
    { "name": "Namur (fr)" }
  ],
  "Bulgaria": [
    { "name": "Burgas" },
    { "name": "Haskovo" },
    { "name": "Loveč" },
    { "name": "Montana" },
    { "name": "Plovdiv" },
    { "name": "Ruse" },
    { "name": "Sofija" },
    { "name": "Sofija-Grad" },
    { "name": "Varna" }
  ],
  "Croatia": [
    { "name": "Bjelovarsko-bilogorska županija" },
    { "name": "Brodsko-posavska županija" },
    { "name": "Dubrovačko-neretvanska županija" },
    { "name": "Istarska županija" },
    { "name": "Karlovačka županija" },
    { "name": "Koprivničko-križevačka županija" },
    { "name": "Krapinsko-zagorska županija" },
    { "name": "Ličko-senjska županija" },
    { "name": "Međimurska županija" },
    { "name": "Osječko-baranjska županija" },
    { "name": "Požeško-slavonska županija" },
    { "name": "Primorsko-goranska županija" },
    { "name": "Sisačko-moslavačka županija" },
    { "name": "Splitsko-dalmatinska županija" },
    { "name": "Šibensko-kninska županija" },
    { "name": "Varaždinska županija" },
    { "name": "Virovitičko-podravska županija" },
    { "name": "Vukovarsko-srijemska županija" },
    { "name": "Zadarska županija" },
    { "name": "Zagrebačka županija" }
  ],
  "Cyprus": [
    { "name": "Ammochostos (Mağusa)" },
    { "name": "Keryneia (Girne)" },
    { "name": "Larnaka" },
    { "name": "Lefkosia (Lefkoşa)" },
    { "name": "Lemesos (Leymosun)" },
    { "name": "Pafos (Baf)" }
  ],
  "Czech Republic": [
    { "name": "Praha" },
    { "name": "Jihočeský kraj" },
    { "name": "Jihomoravský kraj" },
    { "name": "Severočeský kraj" },
    { "name": "Severomoravský kraj" },
    { "name": "Středočeský kraj" },
    { "name": "Východočeský kraj" },
    { "name": "Západočeský kraj" }
  ],
  "Denmark": [
    { "name": "Frederiksberg" },
    { "name": "København" },
    { "name": "Bornholm" },
    { "name": "Frederiksborg" },
    { "name": "Fyn" },
    { "name": "København" },
    { "name": "Nordjylland" },
    { "name": "Ribe" },
    { "name": "Ringkøbing" },
    { "name": "Roskilde" },
    { "name": "Storstrøm" },
    { "name": "Sønderjylland" },
    { "name": "Vejle" },
    { "name": "Vestsjælland" },
    { "name": "Viborg" },
    { "name": "Århus" }
  ],
  "Estonia": [
    { "name": "Harjumaa" },
    { "name": "Hiiumaa" },
    { "name": "Ida-Virumaa" },
    { "name": "Jõgevamaa" },
    { "name": "Järvamaa" },
    { "name": "Läänemaa" },
    { "name": "Lääne-Virumaa" },
    { "name": "Põlvamaa" },
    { "name": "Pärnumaa" },
    { "name": "Raplamaa" },
    { "name": "Saaremaa" },
    { "name": "Tartumaa" },
    { "name": "Valgamaa" },
    { "name": "Viljandimaa" },
    { "name": "Võrumaa" }
  ],
  "Finland": [
    { "name": "Ahvenanmaan lääni" },
    { "name": "Etelä-Suomen lääni" },
    { "name": "Itä-Suomen lääni" },
    { "name": "Lapin lääni" },
    { "name": "Länsi-Suomen lääni" },
    { "name": "Oulun lääni" }
  ],
  "France": [
    { name: "Auvergne-Rhône-Alpes" },
    { name: "Bourgogne-Franche-Comté" },
    { name: "Brittany" },
    { name: "Centre-Val de Loire" },
    { name: "Corsica" },
    { name: "Grand Est" },
    { name: "Hauts-de-France" },
    { name: "Île-de-France" },
    { name: "Normandy" },
    { name: "Nouvelle-Aquitaine" },
    { name: "Occitanie" },
    { name: "Pays de la Loire" },
    { name: "Provence-Alpes-Côte d'Azur" }
  ],
  "Germany": [
    { name: "Baden-Württemberg" },
    { name: "Bavaria" },
    { name: "Berlin" },
    { name: "Brandenburg" },
    { name: "Bremen" },
    { name: "Hamburg" },
    { name: "Hesse" },
    { name: "Lower Saxony" },
    { name: "Mecklenburg-Vorpommern" },
    { name: "North Rhine-Westphalia" },
    { name: "Rhineland-Palatinate" },
    { name: "Saarland" },
    { name: "Saxony" },
    { name: "Saxony-Anhalt" },
    { name: "Schleswig-Holstein" },
    { name: "Thuringia" }
  ],
  "Greece": [
    { name: "Attica" },
    { name: "Central Greece" },
    { name: "Central Macedonia" },
    { name: "Crete" },
    { name: "Eastern Macedonia and Thrace" },
    { name: "Epirus" },
    { name: "Ionian Islands" },
    { name: "Mount Athos" },
    { name: "North Aegean" },
    { name: "Peloponnese" },
    { name: "South Aegean" },
    { name: "Thessaly" },
    { name: "Western Greece" },
    { name: "Western Macedonia" }
  ],
  "Hungary": [
    { name: "Budapest" },
    { name: "Bács-Kiskun" },
    { name: "Baranya" },
    { name: "Békés" },
    { name: "Borsod-Abaúj-Zemplén" },
    { name: "Csongrád-Csanád" },
    { name: "Fejér" },
    { name: "Győr-Moson-Sopron" },
    { name: "Hajdú-Bihar" },
    { name: "Heves" },
    { name: "Jász-Nagykun-Szolnok" },
    { name: "Komárom-Esztergom" },
    { name: "Nógrád" },
    { name: "Pest" },
    { name: "Somogy" },
    { name: "Szabolcs-Szatmár-Bereg" },
    { name: "Tolna" },
    { name: "Vas" },
    { name: "Veszprém" },
    { name: "Zala" }
  ],
  "Ireland": [
    { name: "Connacht" },
    { name: "Leinster" },
    { name: "Munster" },
    { name: "Ulster" }
  ],
  "Italy": [
    { name: "Abruzzo" },
    { name: "Aosta Valley" },
    { name: "Apulia" },
    { name: "Basilicata" },
    { name: "Calabria" },
    { name: "Campania" },
    { name: "Emilia-Romagna" },
    { name: "Friuli-Venezia Giulia" },
    { name: "Lazio" },
    { name: "Liguria" },
    { name: "Lombardy" },
    { name: "Marche" },
    { name: "Molise" },
    { name: "Piedmont" },
    { name: "Sardinia" },
    { name: "Sicily" },
    { name: "Trentino-South Tyrol" },
    { name: "Tuscany" },
    { name: "Umbria" },
    { name: "Veneto" }
  ],
  "Latvia": [
    { name: "Courland" },
    { name: "Latgale" },
    { name: "Riga" },
    { name: "Vidzeme" },
    { name: "Zemgale" }
  ],
  "Lithuania": [
    { name: "Alytus County" },
    { name: "Kaunas County" },
    { name: "Klaipėda County" },
    { name: "Marijampolė County" },
    { name: "Panevėžys County" },
    { name: "Šiauliai County" },
    { name: "Tauragė County" },
    { name: "Telšiai County" },
    { name: "Utena County" },
    { name: "Vilnius County" }
  ],
  "Luxembourg": [
    { name: "Canton of Capellen" },
    { name: "Canton of Clervaux" },
    { name: "Canton of Diekirch" },
    { name: "Canton of Echternach" },
    { name: "Canton of Esch-sur-Alzette" },
    { name: "Canton of Grevenmacher" },
    { name: "Canton of Luxembourg" },
    { name: "Canton of Mersch" },
    { name: "Canton of Redange" },
    { name: "Canton of Remich" },
    { name: "Canton of Vianden" },
    { name: "Canton of Wiltz" }
  ],
  "Malta": [
    { name: "Gozo" },
    { name: "Malta" }
  ],
  "Netherlands": [
    { name: "Drenthe" },
    { name: "Flevoland" },
    { name: "Friesland" },
    { name: "Gelderland" },
    { name: "Groningen" },
    { name: "Limburg" },
    { name: "North Brabant" },
    { name: "North Holland" },
    { name: "Overijssel" },
    { name: "South Holland" },
    { name: "Utrecht" },
    { name: "Zeeland" }
  ],
  "Poland": [
    { name: "Greater Poland" },
    { name: "Kuyavian-Pomeranian" },
    { name: "Lesser Poland" },
    { name: "Łódź" },
    { name: "Lower Silesian" },
    { name: "Lublin" },
    { name: "Lubusz" },
    { name: "Masovian" },
    { name: "Opole" },
    { name: "Podkarpackie" },
    { name: "Podlaskie" },
    { name: "Pomeranian" },
    { name: "Silesian" },
    { name: "Subcarpathian" },
    { name: "Swietokrzyskie" },
    { name: "Warmian-Masurian" },
    { name: "West Pomeranian" }
  ],
  "Portugal": [
    { name: "Azores" },
    { name: "Madeira" },
    { name: "Norte" },
    { name: "Centro" },
    { name: "Lisbon" },
    { name: "Alentejo" },
    { name: "Algarve" },
    { name: "Autonomous Region of the Azores" },
    { name: "Autonomous Region of Madeira" }
  ],
  "Romania": [
    { name: "Alba" },
    { name: "Arad" },
    { name: "Argeș" },
    { name: "Bacău" },
    { name: "Bihor" },
    { name: "Bistrița-Năsăud" },
    { name: "Botoșani" },
    { name: "Brașov" },
    { name: "Brăila" },
    { name: "Buzău" },
    { name: "Caraș-Severin" },
    { name: "Călărași" },
    { name: "Cluj" },
    { name: "Constanța" },
    { name: "Covasna" },
    { name: "Dâmbovița" },
    { name: "Dolj" },
    { name: "Galați" },
    { name: "Giurgiu" },
    { name: "Gorj" },
    { name: "Harghita" },
    { name: "Hunedoara" },
    { name: "Ialomița" },
    { name: "Iași" },
    { name: "Ilfov" },
    { name: "Maramureș" },
    { name: "Mehedinți" },
    { name: "Mureș" },
    { name: "Neamț" },
    { name: "Olt" },
    { name: "Prahova" },
    { name: "Satu Mare" },
    { name: "Sălaj" },
    { name: "Sibiu" },
    { name: "Suceava" },
    { name: "Teleorman" },
    { name: "Timiș" },
    { name: "Tulcea" },
    { name: "Vâlcea" },
    { name: "Vaslui" },
    { name: "Vrancea" }
  ],
  "Slovakia": [
    { name: "Bratislava Region" },
    { name: "Trnava Region" },
    { name: "Trenčín Region" },
    { name: "Nitra Region" },
    { name: "Žilina Region" },
    { name: "Banská Bystrica Region" },
    { name: "Prešov Region" },
    { name: "Košice Region" }
  ],
  "Slovenia": [
    { name: "Ajdovščina Municipality" },
    { name: "Beltinci Municipality" },
    { name: "Bled Municipality" },
    { name: "Bohinj Municipality" },
    { name: "Borovnica Municipality" },
    { name: "Bovec Municipality" },
    { name: "Brda Municipality" },
    { name: "Brežice Municipality" },
    { name: "Celje Municipality" },
    { name: "Cerknica Municipality" },
    { name: "Cerkno Municipality" },
    { name: "Črenšovci Municipality" },
    { name: "Črna na Koroškem Municipality" },
    { name: "Črnomelj Municipality" },
    { name: "Destrnik Municipality" },
    { name: "Divača Municipality" },
    { name: "Dobrepolje Municipality" },
    { name: "Dobrova–Polhov Gradec Municipality" },
    { name: "Dol pri Ljubljani Municipality" },
    { name: "Domžale Municipality" },
    { name: "Dornava Municipality" },
    { name: "Dravograd Municipality" },
    { name: "Duplek Municipality" },
    { name: "Gorenja Vas–Poljane Municipality" },
    { name: "Gorišnica Municipality" },
    { name: "Gorje Municipality" },
    { name: "Gornja Radgona Municipality" },
    { name: "Gornji Grad Municipality" },
    { name: "Gornji Petrovci Municipality" },
    { name: "Grad Municipality" },
    { name: "Grosuplje Municipality" },
    { name: "Hajdina Municipality" },
    { name: "Hoče–Slivnica Municipality" },
    { name: "Hodoš Municipality" },
    { name: "Horjul Municipality" },
    { name: "Hrastnik Municipality" },
    { name: "Hrpelje-Kozina Municipality" },
    { name: "Idrija Municipality" },
    { name: "Ig Municipality" },
    { name: "Ilirska Bistrica Municipality" },
    { name: "Ivančna Gorica Municipality" },
    { name: "Izola Municipality" },
    { name: "Jesenice Municipality" },
    { name: "Jezersko Municipality" },
    { name: "Juršinci Municipality" },
    { name: "Kamnik Municipality" },
    { name: "Kanal ob Soči Municipality" },
    { name: "Kidričevo Municipality" },
    { name: "Kobarid Municipality" },
    { name: "Kobilje Municipality" },
    { name: "Kočevje Municipality" },
    { name: "Komen Municipality" },
    { name: "Komenda Municipality" },
    { name: "Koper Municipality" },
    { name: "Kozje Municipality" },
    { name: "Kranj Municipality" },
    { name: "Kranjska Gora Municipality" },
    { name: "Krško Municipality" },
    { name: "Kungota Municipality" },
    { name: "Kuzma Municipality" },
    { name: "Laško Municipality" },
    { name: "Lenart Municipality" },
    { name: "Lendava Municipality" },
    { name: "Litija Municipality" },
    { name: "Ljubljana Municipality" },
    { name: "Ljubno Municipality" },
    { name: "Ljutomer Municipality" },
    { name: "Log–Dragomer Municipality" },
    { name: "Logatec Municipality" },
    { name: "Loška Dolina Municipality" },
    { name: "Loški Potok Municipality" },
    { name: "Lovrenc na Pohorju Municipality" },
    { name: "Luče Municipality" },
    { name: "Lukovica Municipality" },
    { name: "Majšperk Municipality" },
    { name: "Makole Municipality" },
    { name: "Maribor Municipality" },
    { name: "Markovci Municipality" },
    { name: "Medvode Municipality" },
    { name: "Mengeš Municipality" },
    { name: "Metlika Municipality" },
    { name: "Mežica Municipality" },
    { name: "Miklavž na Dravskem Polju Municipality" },
    { name: "Miren–Kostanjevica Municipality" },
    { name: "Mirna Peč Municipality" },
    { name: "Mislinja Municipality" },
    { name: "Mokronog–Trebelno Municipality" },
    { name: "Moravče Municipality" },
    { name: "Moravske Toplice Municipality" },
    { name: "Mozirje Municipality" },
    { name: "Municipality of Apače" },
    { name: "Municipality of Cirkulane" },
    { name: "Municipality of Hodoš" },
    { name: "Municipality of Šalovci" },
    { name: "Municipality of Šmartno pri Litiji" },
    { name: "Murska Sobota Municipality" },
    { name: "Muta Municipality" },
    { name: "Naklo Municipality" },
    { name: "Nazarje Municipality" },
    { name: "Nova Gorica Municipality" },
    { name: "Občina Benedikt" },
    { name: "Občina Bistrica ob Sotli" },
    { name: "Občina Bloke" },
    { name: "Občina Braslovče" },
    { name: "Občina Cankova" },
    { name: "Občina Črenšovci" },
    { name: "Občina Dobrna" },
    { name: "Občina Grad" },
    { name: "Občina Hajdina" },
    { name: "Občina Hoče-Slivnica" },
    { name: "Občina Hodoš" },
    { name: "Občina Horjul" },
    { name: "Občina Jezersko" },
    { name: "Občina Juršinci" },
    { name: "Občina Kočevje" },
    { name: "Občina Komenda" },
    { name: "Občina Kostel" },
    { name: "Občina Križevci" },
    { name: "Občina Lovrenc na Pohorju" },
    { name: "Občina Majšperk" },
    { name: "Občina Markovci" },
    { name: "Občina Medvode" },
    { name: "Občina Miklavž na Dravskem Polju" },
    { name: "Občina Miren-Kostanjevica" },
    { name: "Občina Mokronog-Trebelno" },
    { name: "Občina Moravče" },
    { name: "Občina Moravske Toplice" },
    { name: "Občina Mozirje" },
    { name: "Občina Muta" },
    { name: "Občina Naklo" },
    { name: "Občina Nazarje" },
    { name: "Občina Nova Gorica" },
    { name: "Občina Odranci" },
    { name: "Občina Oplotnica" },
    { name: "Občina Ormož" },
    { name: "Občina Osilnica" },
    { name: "Občina Pesnica" },
    { name: "Občina Piran" },
    { name: "Občina Podčetrtek" },
    { name: "Občina Podlehnik" },
    { name: "Občina Podvelka" },
    { name: "Občina Poljčane" },
    { name: "Občina Polzela" },
    { name: "Občina Prebold" },
    { name: "Občina Prevalje" },
    { name: "Občina Puconci" },
    { name: "Občina Rače-Fram" },
    { name: "Občina Radeče" },
    { name: "Občina Radečee" },
    { name: "Občina Radenci" },
    { name: "Občina Radlje ob Dravi" },
    { name: "Občina Radovljica" },
    { name: "Občina Ravne na Koroškem" },
    { name: "Občina Razkrižje" },
    { name: "Občina Rečica ob Savinji" },
    { name: "Občina Renče-Vogrsko" },
    { name: "Občina Ribnica" },
    { name: "Občina Ribnica na Pohorju" },
    { name: "Občina Rogaška Slatina" },
    { name: "Občina Rogatec" },
    { name: "Občina Ruše" },
    { name: "Občina Selnica ob Dravi" },
    { name: "Občina Semič" },
    { name: "Občina Sevnica" },
    { name: "Občina Sežana" },
    { name: "Občina Slovenj Gradec" },
    { name: "Občina Slovenska Bistrica" },
    { name: "Občina Slovenske Konjice" },
    { name: "Občina Sodražica" },
    { name: "Občina Solčava" },
    { name: "Občina Središče ob Dravi" },
    { name: "Občina Starše" },
    { name: "Občina Straža" },
    { name: "Občina Sveta Ana" },
    { name: "Občina Sveti Andraž v Slovenskih Goricah" },
    { name: "Občina Sveti Jurij ob Ščavnici" },
    { name: "Občina Sveti Tomaž" },
    { name: "Občina Tabor" },
    { name: "Občina Tišina" },
    { name: "Občina Tolmin" },
    { name: "Občina Trbovlje" },
    { name: "Občina Trebnje" },
    { name: "Občina Trnovska Vas" },
    { name: "Občina Trzin" },
    { name: "Občina Turnišče" },
    { name: "Občina Velika Polana" },
    { name: "Občina Velike Lašče" },
    { name: "Občina Veržej" },
    { name: "Občina Videm" },
    { name: "Občina Vipava" },
    { name: "Občina Vitanje" },
    { name: "Občina Vodice" },
    { name: "Občina Vojnik" },
    { name: "Občina Vransko" },
    { name: "Občina Vrhnika" },
    { name: "Občina Vuzenica" },
    { name: "Občina Zagorje ob Savi" },
    { name: "Občina Zagreb" },
    { name: "Občina Zavrč" },
    { name: "Občina Zreče" },
    { name: "Občina Žalec" },
    { name: "Občina Železniki" },
    { name: "Občina Žetale" },
    { name: "Občina Žiri" },
    { name: "Občina Žirovnica" },
    { name: "Občina Žužemberk" },
    { name: "Ormož Municipality" },
    { name: "Osilnica Municipality" },
    { name: "Pesnica Municipality" },
    { name: "Piran Municipality" },
    { name: "Podčetrtek Municipality" },
    { name: "Podlehnik Municipality" },
    { name: "Podvelka Municipality" },
    { name: "Poljčane Municipality" },
    { name: "Polzela Municipality" },
    { name: "Prebold Municipality" },
    { name: "Prevalje Municipality" },
    { name: "Puconci Municipality" },
    { name: "Rače-Fram Municipality" },
    { name: "Radeče Municipality" },
    { name: "Radeče Municipality" },
    { name: "Radenci Municipality" },
    { name: "Radlje ob Dravi Municipality" },
    { name: "Radovljica Municipality" },
    { name: "Ravne na Koroškem Municipality" },
    { name: "Razkrižje Municipality" },
    { name: "Rečica ob Savinji Municipality" },
    { name: "Renče-Vogrsko Municipality" },
    { name: "Ribnica Municipality" },
    { name: "Ribnica na Pohorju Municipality" },
    { name: "Rogaška Slatina Municipality" },
    { name: "Rogatec Municipality" },
    { name: "Ruše Municipality" },
    { name: "Selnica ob Dravi Municipality" },
    { name: "Semič Municipality" },
    { name: "Sevnica Municipality" },
    { name: "Sežana Municipality" },
    { name: "Slovenj Gradec Municipality" },
    { name: "Slovenska Bistrica Municipality" },
    { name: "Slovenske Konjice Municipality" },
    { name: "Sodražica Municipality" },
    { name: "Solčava Municipality" },
    { name: "Središče ob Dravi Municipality" },
    { name: "Starše Municipality" },
    { name: "Straža Municipality" },
    { name: "Sveta Ana Municipality" },
    { name: "Sveti Andraž v Slovenskih Goricah Municipality" },
    { name: "Sveti Jurij ob Ščavnici Municipality" },
    { name: "Sveti Tomaž Municipality" },
    { name: "Tabor Municipality" },
    { name: "Tišina Municipality" },
    { name: "Tolmin Municipality" },
    { name: "Trbovlje Municipality" },
    { name: "Trebnje Municipality" },
    { name: "Trnovska Vas Municipality" },
    { name: "Trzin Municipality" },
    { name: "Turnišče Municipality" },
    { name: "Velika Polana Municipality" },
    { name: "Velike Lašče Municipality" },
    { name: "Veržej Municipality" },
    { name: "Videm Municipality" },
    { name: "Vipava Municipality" },
    { name: "Vitanje Municipality" },
    { name: "Vodice Municipality" },
    { name: "Vojnik Municipality" },
    { name: "Vransko Municipality" },
    { name: "Vrhnika Municipality" },
    { name: "Vuzenica Municipality" },
    { name: "Zagorje ob Savi Municipality" },
    { name: "Zagreb Municipality" },
    { name: "Zavrč Municipality" },
    { name: "Zreče Municipality" },
    { name: "Žalec Municipality" },
    { name: "Železniki Municipality" },
    { name: "Žetale Municipality" },
    { name: "Žiri Municipality" },
    { name: "Žirovnica Municipality" },
    { name: "Žužemberk Municipality" }
  ],
  "Spain": [
    { name: "Andalusia" },
    { name: "Aragon" },
    { name: "Asturias" },
    { name: "Balearic Islands" },
    { name: "Basque Country" },
    { name: "Canary Islands" },
    { name: "Cantabria" },
    { name: "Castile and León" },
    { name: "Castilla-La Mancha" },
    { name: "Catalonia" },
    { name: "Extremadura" },
    { name: "Galicia" },
    { name: "La Rioja" },
    { name: "Madrid" },
    { name: "Murcia" },
    { name: "Navarre" },
    { name: "Valencian Community" }
  ],
  "Sweden": [
    { name: "Blekinge County" },
    { name: "Dalarna County" },
    { name: "Gävleborg County" },
    { name: "Gotland County" },
    { name: "Halland County" },
    { name: "Jönköping County" },
    { name: "Kalmar County" },
    { name: "Kronoberg County" },
    { name: "Norrbotten County" },
    { name: "Örebro County" },
    { name: "Östergötland County" },
    { name: "Skåne County" },
    { name: "Södermanland County" },
    { name: "Stockholm County" },
    { name: "Uppsala County" },
    { name: "Värmland County" },
    { name: "Västerbotten County" },
    { name: "Västernorrland County" },
    { name: "Västmanland County" },
    { name: "Västra Götaland County" }
  ]
};
