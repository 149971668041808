import React from "react";
import { Button } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";

import { useSurveyStore } from "../../Stores/SurveyStore";

const styles /** // TODO: replace with styled-components */ = {
  button: {
    backgroundColor: "#c43812",
    color: "#fff",
  },
};

const StyledButton = styled(Button)`
  background-color: #c43812;
  color: #fff;
`;

function SaveAndProceed() {
  const surveyStore = useSurveyStore();

  return (
    <StyledButton
      onClick={() => {
        surveyStore.setRoomOpen(false);
      }}
      sx={styles.button}
    >
      SAVE AND PROCEED
    </StyledButton>
  );
}

export default observer(SaveAndProceed);
